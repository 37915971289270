import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import back from "assets/images/arrow-left.svg";
import addonIcon from "assets/images/add-addon-icon.svg";
import { addItem } from "store/Features/Category/Category Items/categoryItemsServices";
import { useDispatch, useSelector } from "react-redux";
import { showNotification } from "utils/customNotification";
import {
  clearAddonList,
  clearEnteredItems,
  handleOnChangeItemAdd,
} from "store/Features/Category/Category Items/categoryItemsSlice";
import { usePageTitle } from "hooks/usePageTitle";
import { MiniSpinnerComponent } from "components/Spinner";
import { useLocale } from "localization/context";

const currency = process.env.REACT_APP_CURRENCY;
const AddCategoryItem = () => {
  const {
    state: { add_category_item },
  } = useLocale();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categoryList } = useSelector((state) => state.category);
  const { addonList, enteredItem } = useSelector((state) => state.items);
  const [selectedCategory, setSelectedCategory] = useState();
  const [isAddingItem, setIsAddingItem] = useState(false);
  usePageTitle(add_category_item.addItemTitle);

  useEffect(() => {
    if (categoryList && categoryList.length > 0) {
      const defaultCategory = categoryList?.find(
        (category) => category.id === parseInt(id)
      );
      if (defaultCategory) {
        setSelectedCategory(defaultCategory.id.toString());
        dispatch(
          handleOnChangeItemAdd({ category_id: defaultCategory.id.toString() })
        );
      } else {
        setSelectedCategory(categoryList[0].id.toString());
      }
    }
  }, [categoryList, dispatch, id]);

  const handleRegistrationInputChange = (name, value) => {
    if (name === "category_id") {
      setSelectedCategory(value);
    }
    dispatch(handleOnChangeItemAdd({ [name]: value }));
  };

  const handleAddCategory = (event) => {
    if (isAddingItem) return;
    event.preventDefault();

    if (!enteredItem.food_title || !enteredItem.price) {
      showNotification("error", add_category_item.addAllFieldsPrompt);
      return;
    }

    const priceRegex = /^\d+(\.\d{1,2})?$/;
    const isValidPrice = priceRegex.test(enteredItem.price);
    if (!isValidPrice) {
      showNotification("error", add_category_item.enterValidPricePrompt);
      return;
    }
    setIsAddingItem(true);
    const data = {
      category_id: enteredItem.category_id,
      food_title: enteredItem.food_title,
      second_title: enteredItem.second_title,
      price: enteredItem.price,
      addons: JSON.stringify(addonList),
    };

    dispatch(addItem(data))
      .unwrap()
      .then((promiseResult) => {
        if (promiseResult.status) {
          showNotification("success", add_category_item.itemAddedSuccessPrompt);
          navigate(`/admin/category-item/${id}`);
          dispatch(clearAddonList());
          dispatch(clearEnteredItems());
        } else {
          showNotification("error", add_category_item.failedItemAddPrompt);
        }
      })
      .finally(() => setTimeout(() => setIsAddingItem(false), 1000));
  };
  return (
    <div className="add-category add-category-item h-100">
      <form onSubmit={handleAddCategory} className="h-100">
        <div className="page-title position-relative">
          <div className="cus-container d-flex justify-content-center">
            <Link to={`/admin/category-item/${id}`}>
              <img src={back} alt="back" />
            </Link>
            <h4 className="text-capitalize">{add_category_item.addNewItem}</h4>
          </div>
        </div>
        <div className="cus-container admin-scroll">
          <div className="floating-input">
            <label>{add_category_item.selectCategoryLabel}</label>
            <select
              className="floating-control"
              value={selectedCategory}
              onChange={(e) =>
                handleRegistrationInputChange("category_id", e.target.value)
              }
            >
              {categoryList &&
                categoryList.map((category) => (
                  <option value={category.id} key={category.id}>
                    {category.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="floating-input">
            <label>{add_category_item.foodTitleLabel}</label>
            <input
              type="text"
              placeholder={add_category_item.enterFoodTitlePlaceholder}
              className="floating-control"
              defaultValue={enteredItem.food_title}
              onChange={(e) =>
                handleRegistrationInputChange("food_title", e.target.value)
              }
            />
          </div>
          <div className="floating-input">
            <label>{add_category_item.secondTitleLabel}</label>
            <textarea
              className="floating-control big-height"
              placeholder={add_category_item.enterSecondTitlePlaceholder}
              defaultValue={enteredItem.second_title}
              onChange={(e) =>
                handleRegistrationInputChange("second_title", e.target.value)
              }
            ></textarea>
          </div>
          <div className="floating-input">
            <label>{add_category_item.priceLabel}</label>
            <input
              type="text"
              placeholder={add_category_item.enterPricePlaceholder}
              className="floating-control"
              defaultValue={enteredItem.price}
              onChange={(e) =>
                handleRegistrationInputChange("price", e.target.value)
              }
            />
          </div>
          <div className="select-addons">
            <h5>{add_category_item.selectAddonHeading}</h5>
            <ul className="addons-list">
              {addonList &&
                addonList.length > 0 &&
                addonList.map((addOn, index) => {
                  return (
                    <li
                      className="d-flex align-items-center justify-content-between"
                      key={index}
                    >
                      <label className="cus-checkbox position-relative">
                        {addOn.name}
                        <input type="checkbox" defaultChecked />
                        <span className="chkbox-bg"></span>
                        <span className="chkbox-chk"></span>
                      </label>
                      <p>
                        {currency}
                        {addOn.price}
                      </p>
                    </li>
                  );
                })}
            </ul>
            <Link
              to={`/admin/add-addon/${id}`}
              className="common-link d-flex align-items-center"
            >
              <img src={addonIcon} alt="add-addon" />
              &nbsp;&nbsp; {add_category_item.addNewAddonText}
            </Link>
          </div>
        </div>
        <div className="bottom-btn">
          <div className="cus-container">
            <button
              type="submit"
              className="border-0 common-btn"
              disabled={isAddingItem}
            >
              {isAddingItem ? (
                <>
                  {add_category_item.addingText} <MiniSpinnerComponent />
                </>
              ) : (
                add_category_item.continueText
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddCategoryItem;
