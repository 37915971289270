import React, { useCallback, useLayoutEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import threeDots from "assets/images/three-dots.svg";
import { useLocale } from "localization/context";

const AddonCheckbox = ({
  addOn,
  handleDeleteAddon,
  handleUpdateAddon,
  handleStatusChange,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const {
    state: { addon_checkbox },
  } = useLocale();
  useLayoutEffect(() => {
    setIsChecked(addOn.status === "Active");
  }, [addOn.status]);

  const onStatusChange = useCallback(() => {
    setIsChecked(!isChecked);
    handleStatusChange(addOn.id, !isChecked);
  }, [addOn.id, handleStatusChange, isChecked]);

  return (
    <li className="d-flex align-items-center justify-content-between">
      <label className="cus-checkbox position-relative">
        {addOn.title}
        <input type="checkbox" checked={isChecked} onChange={onStatusChange} />
        <span className="chkbox-bg"></span>
        <span className="chkbox-chk"></span>
      </label>
      <div className="addon-total">
        <p>€{addOn.price}</p>
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <img src={threeDots} alt="three-dots" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleUpdateAddon(addOn.id)}>
              {addon_checkbox.editDropdown}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleDeleteAddon(addOn?.id)}>
              {addon_checkbox.deleteDropdown}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </li>
  );
};

export default AddonCheckbox;
