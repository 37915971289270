import React, { useEffect, useState } from "react";
import back from "assets/images/arrow-left.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeOrderStatus } from "store/Features/Orders/ordersServices";
import { usePageTitle } from "hooks/usePageTitle";
import { MiniSpinnerComponent } from "components/Spinner";
import { useLocale } from "localization/context";
const currency = process.env.REACT_APP_CURRENCY;

const AdminOrderDetail = () => {
  const {
    state: { admin_order_detail },
  } = useLocale();
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentOrder, setCurrentOrder] = useState(null);
  const { orders } = useSelector((state) => state.orders);
  const [isPending, setIsPending] = useState(false);
  usePageTitle(admin_order_detail.orderDetailTitle);

  useEffect(() => {
    const setOrder = orders.find((order) => order.id === parseInt(id));
    setCurrentOrder(setOrder);
  }, [id, orders]);

  const handleChangeStatus = () => {
    if (isPending) {
      return;
    }
    setIsPending(true);
    if (currentOrder?.status === "Placed") {
      dispatch(
        changeOrderStatus({ order_id: currentOrder?.id, status: "Preparing" })
      )
        .unwrap()
        .then((promiseResult) => {
          if (promiseResult.status) {
            navigate("/admin/home");
          }
        })
        .finally(() => setIsPending(false));
    } else if (currentOrder?.status === "Preparing") {
      dispatch(
        changeOrderStatus({ order_id: currentOrder?.id, status: "Served" })
      )
        .unwrap()
        .then((promiseResult) => {
          if (promiseResult.status) {
            navigate("/admin/home");
          }
        })
        .finally(() => setIsPending(false));
    }
  };

  if (currentOrder && currentOrder !== null) {
    const splitTableName = currentOrder.table_name
      ?.split(/(\d+)/)
      ?.filter(Boolean);

    return (
      <div className="order-detail admin-order-detail h-100">
        <div className="page-title position-relative">
          <div className="cus-container d-flex justify-content-center">
            <Link to="/admin/home">
              <img src={back} alt="back" />
            </Link>
            <h4 className="text-capitalize">#{currentOrder.order_code}</h4>
            <p
              className={`order-status ${
                currentOrder.status === "Preparing" ? "served" : ""
              }`}
            >
              {currentOrder.status}
            </p>
          </div>
        </div>
        <div className="cus-container order-detail-scroll">
          <div className="table-box order-table-no">
            <span>{splitTableName[0] || ""} </span>
            <p>{splitTableName[1] || ""}</p>
          </div>
          <div className="table-box">
            <div className="d-flex justify-content-between">
              <p className="order-title">{admin_order_detail.orderItemText}</p>
              <p className="order-title">{admin_order_detail.amountText}</p>
            </div>
            <ul className="order-item-list justify-content-between">
              {currentOrder.items.length === 0 && (
                <p>{admin_order_detail.noItemsFoundText}</p>
              )}
              {currentOrder.items.length >= 1 &&
                currentOrder.items?.map((itm, index) => (
                  <li className="d-flex" key={index}>
                    <h5 className="text-uppercase">X{itm.qty}</h5>
                    <div className="flex-grow-1">
                      <h5 className="text-capitalize">{itm.food_title}</h5>
                      <p>
                        {itm.addons.map((addon, index) => (
                          <span>
                            {addon.title}
                            {index !== itm.addons.length - 1 && ", "}
                          </span>
                        ))}
                      </p>
                    </div>
                    <p>
                      {currency}
                      {itm.itemPricewithAddon.toFixed(2)}
                    </p>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <div className="bill-detail">
          <div className="cus-container">
            {/* <h5 className="text-capitalize">{admin_order_detail.billDetailsText}</h5> */}
            <ul className="bill-items-list">
              {/* <li className="d-flex justify-content-between">
                <p>Item Total</p>
                <p>
                  {currency}
                  {currentOrder.order_total.toFixed(2)}
                </p>
              </li> */}
              {/* <li className="d-flex justify-content-between">
                <p>Taxes and Charges (10%)</p>
                <p>
                  {currency}
                  {currentOrder.taxes.toFixed(2)}
                </p>
              </li> */}
              <li className="d-flex justify-content-between">
                <p>{admin_order_detail.totalText}</p>
                <p>
                  {currency}
                  {currentOrder.total_amount.toFixed(2)}
                </p>
              </li>
            </ul>
            <button
              onClick={handleChangeStatus}
              disabled={isPending}
              className={`border-0 common-btn ${
                currentOrder.status === "Placed" ? "black-btn" : ""
              }`}
            >
              {admin_order_detail.markAsText}{" "}
              {currentOrder.status === "Preparing"
                ? admin_order_detail.servedText
                : admin_order_detail.preparingText}{" "}
              {isPending && <MiniSpinnerComponent />}
            </button>
          </div>
        </div>
      </div>
    );
  }
};

export default AdminOrderDetail;
