import React, { useRef } from "react";
import lockIcon from "assets/images/lock.png";
import back from "assets/images/arrow-left.svg";
import { Link, useNavigate } from "react-router-dom";
import { showNotification } from "utils/customNotification";
import { useDispatch } from "react-redux";
import { adminForgotPassword } from "store/Features/Auth/Admin/authService";
import { forgotPasswordEmail } from "store/Features/Auth/Admin/authSlice";
import { usePageTitle } from "hooks/usePageTitle";
import { useLocale } from "localization/context";

const ForgotPassword = () => {
  const {
    state: { forgot_password },
  } = useLocale();

  const emailRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  usePageTitle(forgot_password.forgotPasswordTitle);

  const handleSubmit = (event) => {
    event.preventDefault();
    const email = emailRef.current.value;

    if (!email) {
      showNotification("error", forgot_password.enterEmailPrompt);
      return;
    }
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isEmailValid = emailPattern.test(email);
    if (!isEmailValid) {
      showNotification("error", forgot_password.enterValidEmailPrompt);
      return;
    }
    dispatch(adminForgotPassword({ email }))
      .unwrap()
      .then((promiseResult) => {
        if (promiseResult.status) {
          dispatch(forgotPasswordEmail(email));
          localStorage.setItem("forgot-password-email", email);
          localStorage.setItem(
            "forgot-password-token",
            promiseResult?.data?.token
          );
          showNotification("success", promiseResult?.message);
          navigate("/admin/otp-verify", { replace: true });
        } else {
          showNotification("error", forgot_password.emailNotRegistedPrompt);
        }
      });
  };

  return (
    <div className="login-page forgot-pasw">
      <div className="cus-container d-flex flex-column justify-content-between">
        <Link to="/admin/login">
          <img src={back} alt="back" />
        </Link>
        <div className="form-data">
          <div className="text-center">
            <img src={lockIcon} alt="lock" />
          </div>
          <h3 className="text-capitalize text-center">
            {forgot_password.forgotPasswordText}
          </h3>
          <p>
            {forgot_password.sendEmailText}
            <br></br> {forgot_password.yourPasswordText}
          </p>
          <form onSubmit={handleSubmit}>
            <div className="from-group">
              <span>{forgot_password.emailLabel}</span>
              <input
                type="text"
                placeholder={forgot_password.enterEmailPlaceholder}
                ref={emailRef}
              />
            </div>
            <button type="submit" className="border-0 common-btn">
              {forgot_password.submitButton}
            </button>
          </form>
        </div>
        <div className="text-center">
          <p className="text-capitalize already-account">
            {forgot_password.alreadyHaveAccount}
          </p>
          <Link to="/admin/login" className="common-btn border-btn">
            {forgot_password.login}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
