import { createSlice } from '@reduxjs/toolkit';
import { adminLogin, adminForgotPassword, adminOtpVerify, adminLogout } from './authService';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: false,
    message: '',
    error: null,
    adminData: null,
    adminToken: null,
    forgotPasswordEmail: '',
    forgotPasswordToken: '',
    isUpdatedImage: false,
    updated_image_url: '',
  },
  reducers: {
    loaduser: (state, action) => {
      state.adminToken = action.payload;
    },
    forgotPasswordEmail: (state, action) => {
      state.forgotPasswordEmail = action.payload;
    },
    updateProfileImage: (state, action) => {
      state.isUpdatedImage = true;
      state.updated_image_url = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(adminLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(adminLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.adminData = action.payload?.data;
        state.adminToken = action.payload?.data?.token
      })
      .addCase(adminLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(adminForgotPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(adminForgotPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload?.message
        state.forgotPasswordToken = action.payload?.data?.token
      })
      .addCase(adminForgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(adminOtpVerify.pending, (state) => {
        state.loading = true;
      })
      .addCase(adminOtpVerify.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload?.message
      })
      .addCase(adminOtpVerify.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(adminLogout.pending, (state) => {
        state.loading = true;
      })
      .addCase(adminLogout.fulfilled, (state, action) => {
        state.message = action.payload?.message;
        state.adminData = null;
        state.adminToken = null;
      })
      .addCase(adminLogout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});
export const { loaduser, forgotPasswordEmail, updateProfileImage } = authSlice.actions;
export default authSlice.reducer;
