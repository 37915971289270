import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "components/Footer";
import { saveAs } from "file-saver";

import addItem from "assets/images/add-item-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { setActiveState } from "store/Features/Footer/footerSlice";
import {
  changeTableStatus,
  deleteTable,
  getTables,
} from "store/Features/ManageTable/manageTableServices";
import { usePageTitle } from "hooks/usePageTitle";
import Toggle from "components/ToggleSwitch";
import { showNotification } from "utils/customNotification";
import Dropdown from "react-bootstrap/Dropdown";
import threeDots from "assets/images/three-dots.svg";
import { SpinnerComponent } from "components/Spinner";
import { useLocale } from "localization/context";

const ManageTables = () => {
  const {
    state: { manage_tables },
  } = useLocale();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tables, loading } = useSelector((state) => state.tables);
  const [isToggleChanging, setIsToggleChanging] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  usePageTitle(manage_tables.manageTablesTitle);

  useEffect(() => {
    dispatch(setActiveState("managetables"));
    dispatch(getTables());
  }, [dispatch]);

  const handleToggleChange = (table_id) => {
    if (isToggleChanging) {
      return;
    }
    setIsToggleChanging(true);
    dispatch(changeTableStatus({ table_id }))
      .unwrap()
      .then((promiseResult) => {
        if (!promiseResult) {
          showNotification("error", manage_tables.failedChaningPrompt);
        }
      })
      .finally(() => setIsToggleChanging(false));
  };

  const handleDeleteCategory = (table_id) => {
    setIsDeleting(true);
    dispatch(deleteTable({ table_id }))
      .unwrap()
      .then((promiseResult) => {
        if (promiseResult.status) {
          showNotification("success", manage_tables.tableDeletedPrompt);
          dispatch(getTables());
        } else {
          showNotification("error", manage_tables.failedDeleteTablePrompt);
        }
      })
      .finally(() => setIsDeleting(false));
  };

  return (
    <div className="manage-item manage-tables h-100">
      <div className="page-title position-relative">
        <div className="cus-container d-flex justify-content-center">
          <h4 className="text-capitalize">{manage_tables.manageTablesText}</h4>
          {!loading && (
            <Link to="/admin/add-table">
              <img src={addItem} alt="add-item" className="add-item-btn" />
            </Link>
          )}
        </div>
      </div>
      <div className="cus-container admin-scroll">
        {loading && <SpinnerComponent />}
        <ul className="manage-item-list order-detail">
          {!loading &&
            tables &&
            tables.map((table) => (
              <li key={table.id}>
                <div className="table-box d-flex align-items-center justify-content-between">
                  <div className="flex-grow-1">
                    <h5>{table.name}</h5>
                    <Toggle
                      isActive={table.status}
                      id={table.id}
                      handleToggleChange={handleToggleChange}
                      isToggleChanging={isToggleChanging}
                    />
                  </div>
                  <a
                    className="print-btn"
                    download="imageQr.png"
                    href={table.image_base64}
                  >
                    {manage_tables.printQrButton}
                  </a>
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <img src={threeDots} alt="three-dots" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() =>
                          navigate(`/admin/update-table/${table.id}`)
                        }
                      >
                        {manage_tables.editDropdown}
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={isDeleting}
                        onClick={() => handleDeleteCategory(table.id)}
                      >
                        {manage_tables.deleteDropdown}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </li>
            ))}
        </ul>
      </div>
      <Footer isAdmin={true} />
    </div>
  );
};
export default ManageTables;
