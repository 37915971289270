import React, { useState } from 'react';
import Search from 'components/Search';
import Footer from 'components/Footer';
import { Link } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';

import back from 'assets/images/arrow-left.svg';


function SearchPage() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="menu-screen search-page h-100">
      <div className='cus-container'>
      <div className='page-title position-relative'>
            <div className='cus-container d-flex justify-content-center'>
                <Link to="/menu"><img src={back} alt='back' /></Link>
                <h4 className='text-capitalize'>Søg</h4>
            </div>
        </div>
        <Search />
      </div>
      <div className='seprator-cnt d-flex justify-content-between align-items-center'>
        <div className='d-flex align-items-center item-qty'>
          <h5>1 Result Found</h5>
        </div>
      </div>
      {/* when the next button enable you have to add btn-active class inside the scroll-list div to handle the scroll */}
      <div className='cus-container scroll-list'>
        <ul className='item-list'>
          <li className='d-flex justify-content-between'>
            <div className='item-desc'>
              <h5>Rindsuppe</h5>
              <p>mit Frittaten, Leberknödel, Fleischstrudel oder E</p>
              <span>€3,90</span>
            </div>
            <div className='add-remove-qty'>
              <div className='d-flex justify-content-center align-items-center'>
                <button variant="primary" onClick={handleShow} className='p-0 border-0 d-flex align-items-center justify-content-center'>+</button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className='cus-container item-cart-sec'>
        <div className='item-cart d-flex align-items-center justify-content-between'>
          <p>1 Item | €3,90</p>
          <Link to="/">
            next
          </Link>
        </div>
      </div>
      <Footer />
      {/* bottom canvas */}
      <Offcanvas show={show} onHide={handleClose} placement="bottom" className="menu-canvas">
        <Offcanvas.Header closeButton>
          <h4 className='text-capitalize'>Addons</h4>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className='addons-list'>
            <li className='d-flex align-items-center justify-content-between'>
                <label className='cus-radio position-relative'>
                Personal Giant Slice (22.5 CM)
                  <input type='radio' name='radio' />
                  <span class="radio-bg"></span>
                  <span class="radio-chk"></span>
                </label>
                <p>€10</p>
            </li>
            <li className='d-flex align-items-center justify-content-between'>
                <label className='cus-radio position-relative'>
                Regular (Serves 1, 17.7 CM)
                  <input type='radio' name='radio' />
                  <span class="radio-bg"></span>
                  <span class="radio-chk"></span>
                </label>
                <p>€10</p>
            </li>
            <li className='d-flex align-items-center justify-content-between'>
                <label className='cus-radio position-relative'>
                Medium (Serves 2, 24.5 CM)
                  <input type='radio' name='radio' />
                  <span class="radio-bg"></span>
                  <span class="radio-chk"></span>
                </label>
                <p>€10</p>
            </li>
            <li className='d-flex align-items-center justify-content-between'>
                <label className='cus-radio position-relative'>
                Large (Serves 4, 33 CM)
                  <input type='radio' name='radio' />
                  <span class="radio-bg"></span>
                  <span class="radio-chk"></span>
                </label>
                <p>€10</p>
            </li>
          </ul>
          <Link to="/" className='common-btn'>Save & Continue</Link>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}

export default SearchPage;

