import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "utils/axiosInstance";

export const getOrders = createAsyncThunk(
  "orders/get-orders",
  async ({ status }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_admin_token"
          )} `,
        },
      };
      const { data } = await axiosInstance.post(
        `/get-order`,
        {
          status,
        },
        config
      );
      return data;
    } catch (error) {
      const errorMessage = error.response?.data?.message ?? error.message;
      return Promise.reject(errorMessage);
    }
  }
);

export const changeOrderStatus = createAsyncThunk(
  "orders/change-order-status",
  async ({ order_id, status }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_admin_token"
          )} `,
        },
      };
      const { data } = await axiosInstance.post(
        `/order-status`,
        {
          order_id,
          status,
        },
        config
      );
      return data;
    } catch (error) {
      const errorMessage = error.response?.data?.message ?? error.message;
      return Promise.reject(errorMessage);
    }
  }
);
