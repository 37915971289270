import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "utils/axiosInstance";

export const getItemByCategory = createAsyncThunk(
  "categoryItem/getitem-by-category",
  async ({ category_id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_admin_token"
          )} `,
        },
      };
      const { data } = await axiosInstance.post(
        `/item-by-category`,
        { category_id },
        config
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const changeItemStatus = createAsyncThunk(
  "categoryItem/change-item-status",
  async ({ item_id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_admin_token"
          )} `,
        },
      };
      const { data } = await axiosInstance.post(
        `/item-status`,
        { item_id },
        config
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const addItem = createAsyncThunk(
  "categoryItem/add-item",
  async (
    { category_id, food_title, second_title, price, addons },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_admin_token"
          )} `,
        },
      };
      const { data } = await axiosInstance.post(
        `/add-item`,
        { category_id, food_title, second_title, price, addons },
        config
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deleteItem = createAsyncThunk(
  "categoryItem/add-item",
  async ({ item_id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_admin_token"
          )} `,
        },
      };
      const { data } = await axiosInstance.post(
        `/delete-item`,
        { item_id },
        config
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getSingleItem = createAsyncThunk(
  "categoryItem/add-item",
  async ({ item_id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_admin_token"
          )} `,
        },
      };
      const { data } = await axiosInstance.post(
        `/get-item`,
        { item_id },
        config
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const addAddon = createAsyncThunk(
  "categoryItem/add-addon",
  async ({ item_id, title, price }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_admin_token"
          )} `,
        },
      };
      const { data } = await axiosInstance.post(
        `/add-addon`,
        { item_id, title, price },
        config
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deleteAddon = createAsyncThunk(
  "categoryItem/delete-addon",
  async ({ addon_id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_admin_token"
          )} `,
        },
      };
      const { data } = await axiosInstance.post(
        `/delete-addon`,
        { addon_id },
        config
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateSingleItem = createAsyncThunk(
  "categoryItem/add-item",
  async (
    { item_id, category_id, food_title, second_title, price, addons },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_admin_token"
          )} `,
        },
      };
      const { data } = await axiosInstance.post(
        `/update-item`,
        { item_id, category_id, food_title, second_title, price, addons },
        config
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateAddon = createAsyncThunk(
  "categoryItem/add-item",
  async ({ addon_id, title, price }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_admin_token"
          )} `,
        },
      };
      const { data } = await axiosInstance.post(
        `/update-addon`,
        { addon_id, title, price },
        config
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);
