import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "utils/axiosInstance";

const backendURL = process.env.REACT_APP_API_BASE_URL;

export const getCategory = createAsyncThunk(
  "category/getCategory",
  async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_admin_token"
          )} `,
        },
      };
      const { data } = await axiosInstance.get(`/category-list`, config);
      return data;
    } catch (error) {
      const errorMessage = error.response?.data?.message ?? error.message;
      return Promise.reject(errorMessage);
    }
  }
);

export const addCategory = createAsyncThunk(
  "category/addCategory",
  async ({ name, image }, { rejectWithValue }) => {
    try {
      const config = {
        method: "POST",
        redirect: "follow",
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_admin_token"
          )} `,
        },
      };

      let formdata = new FormData();
      formdata.append("name", name);
      formdata.append("image", image);

      let requestOptions = {
        method: config.method,
        headers: config.headers,
        body: formdata,
        redirect: config.redirect,
      };

      const data = await fetch(
        "https://www.api.aprico.at/api/admin/add-category",
        requestOptions
      );
      if (data.status === 401) {
        window.localStorage.clear();
        window.location.href = `/admin/login`;
      }
      return data.json();
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getSingleCategory = createAsyncThunk(
  "category/getSingleCategory",
  async ({ category_id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_admin_token"
          )} `,
        },
      };
      const { data } = await axiosInstance.post(
        `/edit-category`,
        { category_id },
        config
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateCategory = createAsyncThunk(
  "category/updateCategory",
  async (formdata, { rejectWithValue }) => {
    try {
      const config = {
        method: "POST",
        redirect: "follow",
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_admin_token"
          )} `,
        },
      };

      let requestOptions = {
        method: config.method,
        headers: config.headers,
        body: formdata,
        redirect: config.redirect,
      };

      const data = await fetch(`${backendURL}/update-category`, requestOptions);
      if (data.status === 401) {
        window.localStorage.clear();
        window.location.href = `/admin/login`;
      }
      return data.json();
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "category/delete-category",
  async ({ category_id }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_admin_token"
          )} `,
        },
      };
      const { data } = await axiosInstance.post(
        `/delete-category`,
        {
          category_id,
        },
        config
      );
      return data;
    } catch (error) {
      const errorMessage = error.response?.data?.message ?? error.message;
      return Promise.reject(errorMessage);
    }
  }
);

export const changeCategoryStatus = createAsyncThunk(
  "category/change-category-status",
  async ({ category_id }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_admin_token"
          )} `,
        },
      };
      const { data } = await axiosInstance.post(
        `/category-status`,
        {
          category_id,
        },
        config
      );
      return data;
    } catch (error) {
      const errorMessage = error.response?.data?.message ?? error.message;
      return Promise.reject(errorMessage);
    }
  }
);
