import React, { useState, useEffect } from "react";
import Search from "components/Search";
import Footer from "components/Footer";
import { useNavigate } from "react-router-dom";
import logo from "assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { usePageTitle } from "hooks/usePageTitle";
import { SpinnerComponent } from "components/Spinner";
import {
  getCustomerCategory,
  getCustomerItemByCategory,
} from "store/Features/Category/customer/customerCategoryService";
import ItemsList from "./ItemsList";
import {
  addAddon,
  addItemsToCart,
  clearCartItems,
  clearSelectedAddons,
  selectedCategoryNameAndCount,
} from "store/Features/Category/customer/customerCategorySlice";
import { setActiveState } from "store/Features/Footer/footerSlice";
import { useLocale } from "localization/context";

function Menu() {
  const {
    state: { menu },
  } = useLocale();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    loading,
    customerCategory,
    customerItems,
    itemLoading,
    categoryNameAndItemCount,
    addonList,
    addonLoading,
    cartItems,
  } = useSelector((state) => state.customerCategory);

  const [searchInput, setSearchInput] = useState("");
  const [selectedCategory, setSelectedCategory] = useState();
  const [categoryDetails, setCategoryDetails] = useState(null);
  const [itemsByCategory, setItemsByCategory] = useState("all");
  const [selectedItemWithAddon, setSelectedItemWithAddon] = useState(null);

  usePageTitle(menu.menuTitle);

  useEffect(() => {
    if (!customerCategory?.length) {
      dispatch(getCustomerCategory());
    }
    dispatch(setActiveState("menu"));
  }, [dispatch, customerCategory]);
  useEffect(() => {
    if (itemsByCategory !== null) {
      dispatch(getCustomerItemByCategory({ category_id: itemsByCategory }));
    }
  }, [dispatch, itemsByCategory]);
  useEffect(() => {
    if (cartItems.items) {
      cartItems.items.map((cartItm) =>
        cartItm?.addons?.map((cartAddon) =>
          dispatch(
            addAddon({ itemId: cartItm.id, addonId: cartAddon.addon_id })
          )
        )
      );
    }
    return () => dispatch(clearSelectedAddons());
  }, [cartItems.items, dispatch]);
  useEffect(() => {
    if (cartItems && cartItems.items) {
      setSelectedItemWithAddon(cartItems.items);
    }
  }, [cartItems]);

  const filteredItems =
    customerItems &&
    customerItems.filter((item) => {
      const searchValue = searchInput.trim().toLowerCase();
      return (
        item.food_title.toLowerCase().includes(searchValue) ||
        (item.second_title &&
          item.second_title.toLowerCase().includes(searchValue))
      );
    });
  const renderedItems = searchInput ? filteredItems : customerItems;

  const handleClick = (category) => {
    setSelectedCategory(category.id);
    setItemsByCategory(parseInt(category.id));
    setCategoryDetails(category);
  };
  useEffect(() => {
    if (categoryDetails !== null) {
      dispatch(selectedCategoryNameAndCount(categoryDetails));
    }
  }, [categoryDetails, dispatch]);

  const handleClearAll = () => {
    setSelectedCategory();
    setSelectedItemWithAddon(null);
    setItemsByCategory("all");
    dispatch(clearCartItems());
    dispatch(clearSelectedAddons());
    localStorage.removeItem("_cart_item_");
  };

  const isItemSelected = selectedItemWithAddon?.length > 0 ? true : false;
  let totalQty;
  let totalPrice;
  if (isItemSelected) {
    totalQty = selectedItemWithAddon.length;
    totalPrice = selectedItemWithAddon.reduce(
      (acc, curr) => acc + curr.itemPricewithAddon,
      0
    );
  }
  const handleNextPlaceOrder = () => {
    const placeItem = {
      totalQuantity: totalQty,
      totalPrice,
      items: selectedItemWithAddon,
    };
    dispatch(addItemsToCart(placeItem));
    dispatch(clearSelectedAddons());
    localStorage.setItem("_cart_item_", JSON.stringify(placeItem));
    navigate("/place-order");
  };
  return (
    <div className="menu-screen h-100">
      <div className="cus-container">
        <div className="d-flex justify-content-center">
          <img src={logo} alt="logo" className="logo" />
        </div>
        <Search state={searchInput} setState={setSearchInput} />
        <ul className="menu-list d-flex">
          {loading && <SpinnerComponent />}
          {!loading &&
            customerCategory?.length > 0 &&
            customerCategory.map((category) => {
              return (
                category.status === "Active" && (
                  <li
                    key={category.id}
                    onClick={() => handleClick(category)}
                    className={selectedCategory === category.id ? "active" : ""}
                  >
                    <button className="p-0 border-0 bg-transparent">
                      <div
                        style={{ backgroundImage: `url(${category.image})` }}
                      ></div>
                      <h6>{category.name}</h6>
                    </button>
                  </li>
                )
              );
            })}
        </ul>
      </div>
      {selectedCategory && (
        <div className="seprator-cnt d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center item-qty">
            <h5>{categoryNameAndItemCount?.category_name}</h5>
            <p>
              {renderedItems.length}{" "}
              {renderedItems.length === 1 ? menu.itemText : menu.itemsText}
            </p>
          </div>
          <p onClick={handleClearAll}>{menu.clearAllText}</p>
        </div>
      )}
      <div
        className={`cus-container scroll-list ${
          selectedCategory ? "menu-selected" : ""
        } ${isItemSelected > 0 ? "btn-active" : ""} `}
      >
        <ul className="item-list">
          {itemLoading && <SpinnerComponent />}
          {!itemLoading &&
            customerItems?.length > 0 &&
            renderedItems.map((item) => {
              return (
                item.status === "Active" && (
                  <ItemsList
                    item={item}
                    key={item.id}
                    addonLoading={addonLoading}
                    addonList={addonList}
                    setItemWithAddonState={setSelectedItemWithAddon}
                    itemWithAddonState={selectedItemWithAddon}
                  />
                )
              );
            })}
        </ul>
      </div>
      {isItemSelected > 0 && (
        <div className="cus-container item-cart-sec">
          <div className="item-cart d-flex align-items-center justify-content-between">
            <p>
              {totalQty} {totalQty === 1 ? menu.itemText : menu.itemsText} | €
              {totalPrice.toFixed(2)}
            </p>
            <button onClick={handleNextPlaceOrder}>{menu.nextButton}</button>{" "}
          </div>
        </div>
      )}
      <Footer isAdmin={false} />
    </div>
  );
}

export default Menu;
