import React, { useEffect, useState } from "react";
import back from "assets/images/arrow-left.svg";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocale } from "localization/context";

const OrderDetail = () => {
  const { id } = useParams();
  const [customerOrderDetails, setCustomerOrderDetails] = useState(null);
  const { customerOrders } = useSelector((state) => state.customerCategory);
  const {
    state: { order_detail },
  } = useLocale();

  useEffect(() => {
    const setOrder = customerOrders.find((order) => order.order_code === id);
    setCustomerOrderDetails(setOrder);
  }, [customerOrders, id]);

  if (customerOrderDetails) {
    const {
      order_code,
      status,
      table_name,
      items,
      order_total,
      taxes,
      total_amount,
    } = customerOrderDetails;
    return (
      <div className="order-detail h-100">
        <div className="page-title position-relative">
          <div className="cus-container d-flex justify-content-center">
            <Link to="/my-orders">
              <img src={back} alt="back" />
            </Link>
            <h4 className="text-capitalize">#{order_code}</h4>
            <p
              className={`order-status ${
                status === "Preparing" ? "served" : ""
              }`}
            >
              {status}
            </p>
          </div>
        </div>
        <div className="cus-container order-detail-scroll">
          <div className="table-box order-table-no">
            <span>{table_name?.split(/(\d+)/).filter(Boolean)[0]}</span>
            <p>{table_name?.split(/(\d+)/).filter(Boolean)[1]}</p>
          </div>
          <div className="table-box">
            <div className="d-flex justify-content-between">
              <p className="order-title">{order_detail.orderItemsText}</p>
              <p className="order-title">{order_detail.amountText}</p>
            </div>
            <ul className="order-item-list">
              {items?.map((itm, index) => (
                <li className="d-flex justify-content-between" key={index}>
                  <h5 className="text-uppercase">X{itm.qty}</h5>
                  <div className="flex-grow-1">
                    <h5 className="text-capitalize">{itm.food_title}</h5>
                    {/* <p>{itm.addon_title ? itm.addon_title : ""}</p> */}
                    <p>
                      {itm.addons.map((addon, index) => (
                        <span>
                          {addon.title}
                          {index !== itm.addons.length - 1 && ", "}
                        </span>
                      ))}
                    </p>
                  </div>
                  <p>€{itm.price_with_addon.toFixed(2)}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="bill-detail">
          <div className="cus-container">
            {/* <h5 className="text-capitalize">Bill Details</h5> */}
            <ul className="bill-items-list">
              {/* <li className="d-flex justify-content-between">
                <p>Item Total</p>
                <p>€{order_total.toFixed(2)}</p>
              </li> */}
              {/* <li className="d-flex justify-content-between">
                <p>Taxes and Charges (10%)</p>
                <p>€{taxes.toFixed(2)}</p>
              </li> */}
              <li className="d-flex justify-content-between">
                <p>{order_detail.grandTotalText}</p>
                <p>€{total_amount.toFixed(2)}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
};

export default OrderDetail;
