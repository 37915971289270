import React, { useRef, useState } from "react";
import logo from "assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { adminLogin } from "store/Features/Auth/Admin/authService";
import { showNotification } from "utils/customNotification";
import { usePageTitle } from "hooks/usePageTitle";
import { MiniSpinnerComponent } from "components/Spinner";
import { useLocale } from "localization/context";

const Login = () => {
  const {
    state: { login },
  } = useLocale();
  const emailRef = useRef("");
  const passwordRef = useRef("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPending, setIsPending] = useState(false);
  usePageTitle(login.loginTitle);

  const handleLoginSubmit = (event) => {
    event.preventDefault();
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const data = {};

    if (!email || !password) {
      showNotification("error", login.selectAllFieldPrompt);
      return;
    }
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isEmailValid = emailPattern.test(email);
    if (!isEmailValid) {
      showNotification("error", login.enterValidEmailPrompt);
      return;
    }
    data.device_type = "ios";
    data.device_token = "ssdf";
    data.email = email;
    data.password = password;

    setIsPending(true);
    dispatch(adminLogin(data))
      .unwrap()
      .then((promiseResult) => {
        if (promiseResult.status) {
          showNotification("success", login.loginSuccessPrompt);
          localStorage.setItem("_apric_admin_token", promiseResult.data?.token);
          localStorage.setItem("isLoggedIn", true);
          navigate("/admin/home");
        } else {
          showNotification("error", login.loginFailedPrompt);
        }
      })
      .finally(() => setIsPending(false));
  };
  return (
    <div className="login-page h-100">
      <div className="cus-container d-flex flex-column justify-content-between h-100">
        <div className="text-center">
          <img src={logo} alt="loog" />
        </div>
        <div className="form-data">
          <h3 className="text-capitalize text-center">{login.adminLogin}</h3>
          <form onSubmit={handleLoginSubmit}>
            <div className="from-group">
              <span>{login.emailLabel}</span>
              <input
                type="text"
                placeholder={login.enterEmailPlaceholder}
                ref={emailRef}
              />
            </div>
            <div className="from-group">
              <span>{login.passwordLabel}</span>
              <input
                type="password"
                placeholder={login.enterPasswordPlaceholder}
                ref={passwordRef}
              />
            </div>
            <button
              type="submit"
              className="border-0 common-btn"
              disabled={isPending}
            >
              {isPending ? (
                <>
                  {login.loggingButton} <MiniSpinnerComponent />
                </>
              ) : (
                login.loginButton
              )}
            </button>
          </form>
        </div>
        <div className="text-center">
          {isPending ? (
            <span className="common-link">{login.forgotPassworText}</span>
          ) : (
            <Link to="/admin/forgot-password" className="common-link">
              {login.forgotPassworText}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
