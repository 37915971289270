import { toast } from "react-toastify"
import './CustomNotification.css';

const toastConfig = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  draggable: true,
  progress: undefined,
  pauseOnFocusLoss: false,
  theme: "light",
  className: "custom-toast"
}

export const showNotification = (type, message) => {
  if (type === 'success') {
    toast.success(message, toastConfig);
  } else if (type === 'error') {
    toast.error(message, toastConfig)
  }

};