export function formateDate(date) {
  const originalDate = new Date(date);
  const formattedDate = originalDate.toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  const formattedTime = originalDate.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  return { formattedDate, formattedTime }
}