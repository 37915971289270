import { createSlice } from "@reduxjs/toolkit";
import {
  getCustomerCategory,
  getCustomerItemByCategory,
  getCustomerItemAddons,
  customerPlacedOrderList,
  customerGetSingleOrder,
} from "./customerCategoryService";

const initialState = {
  loading: false,
  error: null,
  message: "",
  customerCategory: [],
  itemLoading: false,
  itemError: null,
  customerItems: [],
  customerItemCategory: "",
  category_name: "",
  categoryNameAndItemCount: {
    category_name: "",
    item_count: 0,
  },
  addonLoading: false,
  addonError: null,
  addonList: [],
  cartItems: [],
  customerOrdersLoading: false,
  customerOrdersError: null,
  customerOrders: [],
  customerOrderDetailsLoading: false,
  customerOrderDetails: {},
  customerOrderDetailsError: null,
  selectedAddons: [],
};

const customerCategorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    selectedCategoryNameAndCount: (state, { payload }) => {
      const categoryNameAndItemCount = {
        category_name: payload.name,
      };
      state.categoryNameAndItemCount = categoryNameAndItemCount;
    },
    addItemsToCart: (state, { payload }) => {
      state.cartItems = { ...state.cartItems, ...payload };
    },
    clearAddonFromCartItem: (state, { payload }) => {
      const itemId = payload;
      state.cartItems.items = state.cartItems?.items?.map((item) => {
        if (item.id === itemId) {
          return { ...item, addons: [] };
        }
        return item;
      });
    },
    clearCartItems: (state) => {
      state.cartItems = [];
    },
    addAddon: (state, { payload }) => {
      const { itemId, addonId } = payload;
      const itemIndex = state.selectedAddons.findIndex(
        (item) => item.itemId === itemId
      );

      if (itemIndex === -1) {
        state.selectedAddons.push({ itemId, addons: [addonId] });
      } else {
        const addonsArray = state.selectedAddons[itemIndex].addons;
        const addonIndex = addonsArray.indexOf(addonId);

        if (addonIndex === -1) {
          addonsArray.push(addonId);
        } else {
          addonsArray.splice(addonIndex, 1);
        }
      }
    },
    removeAddon: (state, { payload }) => {
      const itemId = payload;
      const updatedCartItems = state.cartItems?.items?.filter(
        (item) => item.id !== itemId
      );
      const updatedSelectedAddons = state.selectedAddons.filter(
        (item) => item.itemId !== itemId
      );
      state.cartItems.items = updatedCartItems;
      state.selectedAddons = updatedSelectedAddons;
    },
    clearSelectedAddons: (state) => {
      state.selectedAddons = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomerCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.customerCategory = action.payload?.data;
      })
      .addCase(getCustomerCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCustomerItemByCategory.pending, (state) => {
        state.itemLoading = true;
      })
      .addCase(getCustomerItemByCategory.fulfilled, (state, action) => {
        state.itemLoading = false;
        state.customerItems = action.payload?.data;
        state.customerItemCategory = action.payload?.category;
      })
      .addCase(getCustomerItemByCategory.rejected, (state, action) => {
        state.itemLoading = false;
        state.itemError = action.payload;
      })
      .addCase(getCustomerItemAddons.pending, (state) => {
        state.addonLoading = true;
      })
      .addCase(getCustomerItemAddons.fulfilled, (state, action) => {
        state.addonLoading = false;
        state.addonList = action.payload?.data;
      })
      .addCase(getCustomerItemAddons.rejected, (state, action) => {
        state.addonLoading = false;
        state.addonError = action.payload;
      })
      .addCase(customerPlacedOrderList.pending, (state) => {
        state.customerOrdersLoading = true;
      })
      .addCase(customerPlacedOrderList.fulfilled, (state, action) => {
        state.customerOrdersLoading = false;
        state.customerOrders = action.payload?.data;
      })
      .addCase(customerPlacedOrderList.rejected, (state, action) => {
        state.customerOrdersLoading = false;
        state.customerOrdersError = action.payload;
      })
      .addCase(customerGetSingleOrder.pending, (state) => {
        state.customerOrderDetailsLoading = true;
      })
      .addCase(customerGetSingleOrder.fulfilled, (state, action) => {
        state.customerOrderDetailsLoading = false;
        state.customerOrderDetails = action.payload?.data;
      })
      .addCase(customerGetSingleOrder.rejected, (state, action) => {
        state.customerOrderDetailsLoading = false;
        state.customerOrderDetailsError = action.payload;
      });
  },
});
export const {
  selectedCategoryNameAndCount,
  addItemsToCart,
  clearCartItems,
  clearSelectedAddons,
  addAddon,
  removeAddon,
  clearAddonFromCartItem,
} = customerCategorySlice.actions;
export default customerCategorySlice.reducer;
