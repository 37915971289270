import React, { useRef } from "react";
import lockIcon from "assets/images/lock.png";
import back from "assets/images/arrow-left.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showNotification } from "utils/customNotification";
import {
  adminOtpVerify,
  adminResendOtp,
} from "store/Features/Auth/Admin/authService";
import { usePageTitle } from "hooks/usePageTitle";
import { useLocale } from "localization/context";

const OtpVarification = () => {
  const {
    state: { otp_varification },
  } = useLocale();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { forgotPasswordEmail } = useSelector((state) => state.auth);
  const otpRef = useRef("");
  usePageTitle(otp_varification.otpVefificationTitle);

  const handleResendOtp = () => {
    const email = forgotPasswordEmail
      ? forgotPasswordEmail
      : localStorage.getItem("forgot-password-email");
    dispatch(adminResendOtp({ email }))
      .unwrap()
      .then((promiseResult) => {
        if (promiseResult.status) {
          showNotification("success", otp_varification.sendAgainPrompt);
          navigate("/admin/otp-verify", { replace: true });
        } else {
          showNotification("failure", otp_varification.failSentAgain);
          navigate("/admin/forgot-password");
        }
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const otp = otpRef.current.value;
    const email = forgotPasswordEmail
      ? forgotPasswordEmail
      : localStorage.getItem("forgot-password-email");

    if (!otp) return showNotification("error", otp_varification.enterOtpPrompt);
    dispatch(adminOtpVerify({ otp, email }))
      .unwrap()
      .then((promiseResult) => {
        if (promiseResult.status) {
          showNotification("success", otp_varification.otpSuccessPrompt);
          localStorage.removeItem("forgot-password-email");
          navigate("/admin/reset-password", { replace: true });
        } else {
          showNotification("error", otp_varification.invalidOtpPrompt);
        }
      });
  };
  return (
    <div className="login-page forgot-pasw">
      <div className="cus-container d-flex flex-column justify-content-between">
        <Link to="/admin/forgot-password">
          <img src={back} alt="back" />
        </Link>
        <div className="form-data">
          <div className="text-center">
            <img src={lockIcon} alt="lock" />
          </div>
          <h3 className="text-capitalize text-center">
            {otp_varification.otpVerification}
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="from-group">
              <span>{otp_varification.otpLabel}</span>
              <input
                type="text"
                placeholder={otp_varification.enterOtpPlaceholder}
                ref={otpRef}
              />
            </div>
            <button type="submit" className="border-0 common-btn">
              {otp_varification.verifyButton}
            </button>
            {/* <Link to="/admin/reset-password">Submit</Link> */}
          </form>
        </div>
        <div className="text-center" onClick={handleResendOtp}>
          <Link to="/admin/otp-verify" className="common-link">
            {otp_varification.resendOtpText}
          </Link>
        </div>
      </div>
    </div>
  );
};
export default OtpVarification;
