import { createSlice } from "@reduxjs/toolkit";
import { getItemByCategory, getSingleItem } from "./categoryItemsServices";

const initialState = {
  loading: false,
  error: null,
  message: "",
  categoryName: '',
  addonList: [],
  itemsByCategory: [],
  enteredItem: {
    category_id: '',
    food_title: '',
    second_title: '',
    price: ''
  },
  updatedItem: {
    category_id: '',
    food_title: '',
    second_title: '',
    price: ''
  },
  updateAddonList: [],
  editAddon: null,
};

const categoryItemsSlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    addonList: (state, { payload }) => {
      state.addonList.push(payload)
    },
    clearAddonList: (state) => {
      state.addonList = [];
    },
    handleOnChangeItemAdd: (state, action) => {
      state.enteredItem = { ...state.enteredItem, ...action.payload };
    },
    handleUpdateItem: (state, action) => {
      state.updatedItem = { ...state.updatedItem, ...action.payload };
    },
    clearUpdateAddonList: (state) => {
      state.updateAddonList = [];
    },
    clearEnteredItems: (state) => {
      state.enteredItem.category_id = ''
      state.enteredItem.food_title = ''
      state.enteredItem.second_title = ''
      state.enteredItem.price = ''
    },
    clearUpdatedItems: (state) => {
      state.updatedItem.category_id = ''
      state.updatedItem.food_title = ''
      state.updatedItem.second_title = ''
      state.updatedItem.price = ''
    },
    handleEditAddon: (state, action) => {
      state.editAddon = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getItemByCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getItemByCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.categoryName = action.payload?.category;
        state.itemsByCategory = action.payload?.data;
      })
      .addCase(getItemByCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message;
      })
      .addCase(getSingleItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleItem.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.updateAddonList = payload?.data?.addons;
        state.updatedItem.category_id = payload?.data?.category_id;
        state.updatedItem.food_title = payload.data?.food_title;
        state.updatedItem.second_title = payload.data?.second_title;
        state.updatedItem.price = payload.data?.price;
      })
      .addCase(getSingleItem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message;
      })
  },
});
export const { addonList, clearAddonList, handleOnChangeItemAdd, clearEnteredItems, handleUpdateItem, clearUpdatedItems, clearUpdateAddonList, handleEditAddon } = categoryItemsSlice.actions;
export default categoryItemsSlice.reducer;
