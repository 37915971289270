import React, { useRef } from "react";
import back from "assets/images/arrow-left.svg";
import { Link, useNavigate } from "react-router-dom";
import { showNotification } from "utils/customNotification";
import { useDispatch, useSelector } from "react-redux";
import { adminResetPassword } from "store/Features/Auth/Admin/authService";
import { usePageTitle } from "hooks/usePageTitle";
import { useLocale } from "localization/context";

const ResetPassword = () => {
  const {
    state: { reset_password },
  } = useLocale();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const { forgotPasswordToken } = useSelector((state) => state.auth);
  usePageTitle(reset_password.resetPasswordTitle);

  const handleSubmit = (event) => {
    event.preventDefault();
    const password = passwordRef.current.value;
    const confirm_password = confirmPasswordRef.current.value;

    if (!password || !confirm_password) {
      showNotification("error", reset_password.addAllFieldsPrompt);
      return;
    }
    if (password !== confirm_password) {
      showNotification("error", reset_password.bothPassMatchPrompt);
      return;
    }
    if (password.length < 6 || confirm_password.length < 6) {
      showNotification("error", reset_password.passwordLengthPrompt);
    }
    const token = forgotPasswordToken
      ? forgotPasswordToken
      : localStorage.getItem("forgot-password-token");
    dispatch(adminResetPassword({ token, password, confirm_password }))
      .unwrap()
      .then((promiseResult) => {
        if (promiseResult.status) {
          showNotification(
            "success",
            reset_password.passwordChangeSuccessPrompt
          );
          localStorage.removeItem("forgot-password-token");
          navigate("/admin/login");
        } else {
          showNotification("error", reset_password.somethingWrongPrompt);
        }
      });
  };

  return (
    <div className="change-password">
      <div className="page-title position-relative">
        <div className="cus-container d-flex justify-content-center">
          <Link to="/admin/login">
            <img src={back} alt="back" />
          </Link>
          <h4 className="text-capitalize">{reset_password.resetPassword}</h4>
        </div>
      </div>
      <div className="cus-container">
        <form onSubmit={handleSubmit}>
          <div className="from-group">
            <span>{reset_password.newPasswordLabel}</span>
            <input
              type="password"
              placeholder={reset_password.enterNewPasswordPlaceholder}
              ref={passwordRef}
            />
          </div>
          <div className="from-group">
            <span>{reset_password.confirmPasswordLabel}</span>
            <input
              type="password"
              placeholder={reset_password.enterConfirmPasswordPlaceholder}
              ref={confirmPasswordRef}
            />
          </div>
          {/* <div className='bottom-btn'> */}
          <div className="cus-container">
            <button type="submit" className="border-0 common-btn">
              {reset_password.changePasswordButton}
            </button>
          </div>
          {/* </div> */}
        </form>
      </div>
    </div>
  );
};
export default ResetPassword;
