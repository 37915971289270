const validateFileType = (imageType) => {
  const allowdType = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/bmp",
    "image/tiff",
  ];
  return allowdType.includes(imageType);
};

export default validateFileType;
