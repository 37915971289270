import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "utils/axiosInstance";

const backendURL = process.env.REACT_APP_API_BASE_URL;

export const getTables = createAsyncThunk(
  "manageTables/get-tables",
  async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_admin_token"
          )} `,
        },
      };
      const { data } = await axiosInstance.get(
        `${backendURL}/table-list`,
        config
      );
      return data;
    } catch (error) {
      const errorMessage = error.response?.data?.message ?? error.message;
      return Promise.reject(errorMessage);
    }
  }
);

export const changeTableStatus = createAsyncThunk(
  "manageTables/change-table-status",
  async ({ table_id }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_admin_token"
          )} `,
        },
      };
      const { data } = await axiosInstance.post(
        `/table-status`,
        {
          table_id,
        },
        config
      );
      return data;
    } catch (error) {
      const errorMessage = error.response?.data?.message ?? error.message;
      return Promise.reject(errorMessage);
    }
  }
);

export const addTable = createAsyncThunk(
  "manageTables/add-table",
  async ({ name }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_admin_token"
          )} `,
        },
      };
      const { data } = await axiosInstance.post(
        `/add-table`,
        {
          name,
        },
        config
      );
      return data;
    } catch (error) {
      const errorMessage = error.response?.data?.message ?? error.message;
      return Promise.reject(errorMessage);
    }
  }
);

export const deleteTable = createAsyncThunk(
  "manageTables/delete-table",
  async ({ table_id }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_admin_token"
          )} `,
        },
      };
      const { data } = await axiosInstance.post(
        `/delete-table`,
        {
          table_id,
        },
        config
      );
      return data;
    } catch (error) {
      const errorMessage = error.response?.data?.message ?? error.message;
      return Promise.reject(errorMessage);
    }
  }
);

export const getSingleTable = createAsyncThunk(
  "manageTables/get-single-table",
  async ({ table_id }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_admin_token"
          )} `,
        },
      };
      const { data } = await axiosInstance.post(
        `/edit-table`,
        {
          table_id,
        },
        config
      );
      return data;
    } catch (error) {
      const errorMessage = error.response?.data?.message ?? error.message;
      return Promise.reject(errorMessage);
    }
  }
);

export const updateTable = createAsyncThunk(
  "manageTables/update-table",
  async ({ table_id, name }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_admin_token"
          )} `,
        },
      };
      const { data } = await axiosInstance.post(
        `/update-table`,
        {
          table_id,
          name,
        },
        config
      );
      return data;
    } catch (error) {
      const errorMessage = error.response?.data?.message ?? error.message;
      return Promise.reject(errorMessage);
    }
  }
);
