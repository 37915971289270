import enStrings from "../en.json";
import deStrings from "../de.json";

export const REGIONS = {
  EN: "EN",
  DE: "DE",
};

export const LOCALE_STRINGS = {
  [REGIONS.EN]: enStrings,
  [REGIONS.DE]: deStrings,
};
