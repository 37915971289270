import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "components/Footer";

import orderImg from "assets/images/order-img.png";
import noOrder from "assets/images/no-order.svg";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useDispatch, useSelector } from "react-redux";
import { customerPlacedOrderList } from "store/Features/Category/customer/customerCategoryService";
import { SpinnerComponent } from "components/Spinner";
import { setActiveState } from "store/Features/Footer/footerSlice";
import { formateDate } from "utils/FormateData";
import { usePageTitle } from "hooks/usePageTitle";
import { useLocale } from "localization/context";

function MyOrders() {
  const {
    state: { my_orders },
  } = useLocale();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("active");
  const { customerOrders, customerOrdersLoading } = useSelector(
    (state) => state.customerCategory
  );
  usePageTitle(my_orders.ordersTitle);

  useEffect(() => {
    const data = {
      ordercode_array: localStorage.getItem("order_code"),
      status: activeTab,
    };
    dispatch(customerPlacedOrderList(data));
    dispatch(setActiveState("my-orders"));
  }, [dispatch, activeTab]);

  const handleTabChange = (tabKey) => {
    if (tabKey === "home") {
      setActiveTab("active");
    } else if (tabKey === "profile") {
      setActiveTab("history");
    }
  };

  return (
    <div className="my-orders h-100">
      <div className="page-title position-relative">
        <div className="cus-container d-flex justify-content-center">
          <h4 className="text-capitalize">{my_orders.myOrdersText}</h4>
        </div>
      </div>
      <div className="cus-container client-order">
        <Tabs
          defaultActiveKey="home"
          id="uncontrolled-tab-example"
          className="order-tab"
          onSelect={handleTabChange}
        >
          <Tab eventKey="home" title={my_orders.activeOrdersTitle}>
            {customerOrdersLoading && <SpinnerComponent />}
            {!customerOrdersLoading &&
              (typeof customerOrders === "undefined" ||
                customerOrders?.length === 0) && (
                <div
                  key="no-order"
                  className="d-flex flex-column justify-content-center no-order"
                >
                  <img src={noOrder} alt="no-order" />
                  <p>{my_orders.noActiveOrdersFound}</p>
                </div>
              )}
            {customerOrders &&
              !customerOrdersLoading &&
              customerOrders.map((order) => {
                const date = formateDate(order.created_at);
                return (
                  (order.status === "Preparing" ||
                    order.status === "Placed") && (
                    <Link
                      to={`/order-detail/${order.order_code}`}
                      className="myorderd-list"
                      key={order.id}
                    >
                      <div className="orderid-title d-flex justify-content-between">
                        <div className="d-flex">
                          <div className="order-img">
                            <img src={orderImg} alt="order" />
                          </div>
                          <div className="order-price">
                            <h5>#{order.order_code}</h5>
                            <p>€{order.total_amount.toFixed(2)}</p>
                          </div>
                        </div>
                        <div>
                          <p
                            className={`order-status ${
                              order.status === "Preparing" ? "preparing" : ""
                            }`}
                          >
                            {order.status}
                          </p>
                        </div>
                      </div>
                      <div className="ordered-item">
                        {order.items?.length > 0 &&
                          order.items.map((item) => (
                            <p key={item.id}>
                              {item.food_title} X {item.qty}
                            </p>
                          ))}
                      </div>
                      <div className="ordered-table d-flex justify-content-between">
                        <p>#{order.table_name}</p>
                        <p>
                          {date.formattedDate} {date.formattedTime}
                        </p>
                      </div>
                    </Link>
                  )
                );
              })}
          </Tab>
          <Tab eventKey="profile" title={my_orders.orderHistoryTitle}>
            {customerOrdersLoading && <SpinnerComponent />}
            {!customerOrdersLoading &&
              (typeof customerOrders === "undefined" ||
                customerOrders?.length === 0) && (
                <div
                  key="no-order"
                  className="d-flex flex-column justify-content-center no-order"
                >
                  <img src={noOrder} alt="no-order" />
                  <p>{my_orders.noOrderHistoryFound}</p>
                </div>
              )}
            {customerOrders &&
              !customerOrdersLoading &&
              customerOrders.map((order) => {
                const date = formateDate(order.created_at);
                return (
                  order.status === "Served" && (
                    <Link
                      to={`/order-detail/${order.order_code}`}
                      className="myorderd-list"
                      key={order.id}
                    >
                      <div className="orderid-title d-flex justify-content-between">
                        <div className="d-flex">
                          <div className="order-img">
                            <img src={orderImg} alt="order" />
                          </div>
                          <div className="order-price">
                            <h5>#{order.order_code}</h5>
                            <p>€{order.total_amount.toFixed(2)}</p>
                          </div>
                        </div>
                        <div>
                          <p
                            className={`order-status ${
                              order.status === "Served" ? "served" : ""
                            }`}
                          >
                            {order.status}
                          </p>
                        </div>
                      </div>
                      <div className="ordered-item">
                        {order.items?.length > 0 &&
                          order.items.map((item) => (
                            <p key={item.id}>
                              {item.food_title} X {item.qty}
                            </p>
                          ))}
                      </div>
                      <div className="ordered-table d-flex justify-content-between">
                        <p>#{order.table_name}</p>
                        <p>
                          {date.formattedDate} {date.formattedTime}
                        </p>
                      </div>
                    </Link>
                  )
                );
              })}
          </Tab>
        </Tabs>
      </div>
      <Footer />
    </div>
  );
}

export default MyOrders;
