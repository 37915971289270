import React from "react";
import { LOCALE_STRINGS, REGIONS } from "./constants";

const initialState = {
  strings: LOCALE_STRINGS[REGIONS.EN],
};

const LocalizationContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_LOCALE": {
      return {
        strings: LOCALE_STRINGS[action.payload.region],
      };
    }
    default:
      return state;
  }
};

export const LocalizationProvider = ({ children }) => {
  const [{ strings }, dispatch] = React.useReducer(reducer, initialState);

  return (
    <LocalizationContext.Provider value={{ state: strings, dispatch }}>
      {children}
    </LocalizationContext.Provider>
  );
};

export const useLocale = () => React.useContext(LocalizationContext);
// const { state: { add_addon } } = useLocale(); // import to use

// https://www.menu.aprico.at/?table_id=11&table_code=381719
