import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import back from "assets/images/arrow-left.svg";
import changeImg from "assets/images/change-img.svg";
import { useDispatch } from "react-redux";
import { addCategory } from "store/Features/Category/categoryServices";
import { showNotification } from "utils/customNotification";
import validateFileType from "utils/validateFileType";
import { usePageTitle } from "hooks/usePageTitle";
import logo from "assets/images/logo.png";
import { MiniSpinnerComponent } from "components/Spinner";
import { useLocale } from "localization/context";

const AddCategory = () => {
  const {
    state: { add_category },
  } = useLocale();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const categoryNameRef = useRef();
  const imageRef = useRef();
  const [selectedImage, setSelectedImage] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  usePageTitle(add_category.addCategoryTitle);

  const handleRegistrationInputChange = async (name, value, isFileControl) => {
    if (isFileControl) {
      if (name === "cat-image") {
        const file = imageRef.current.files[0];
        const reader = new FileReader();
        if (!validateFileType(file.type)) {
          showNotification("error", add_category.selectImageFilePrompt);
          return;
        }
        reader.onload = () => {
          setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
      return;
    }
  };

  const handleAddCategory = () => {
    const name = categoryNameRef.current.value;
    const image = imageRef.current.files[0];

    if (!name || !image) {
      showNotification("error", add_category.addBothFieldPrompt);
      return;
    }
    setIsAdding(true);
    dispatch(addCategory({ name, image }))
      .unwrap()
      .then((promiseResult) => {
        if (promiseResult.status) {
          showNotification("success", add_category.categoryAddedSuccessPrompt);
          navigate("/admin/manage-items");
        } else {
          showNotification("error", add_category.failAddingCategoryPrompt);
        }
      })
      .finally(() => setTimeout(() => setIsAdding(false), 1000));
  };

  const setDisplayImage = selectedImage ? selectedImage : logo;

  return (
    <div className="add-category h-100">
      <div className="page-title position-relative">
        <div className="cus-container d-flex justify-content-center">
          {!isAdding && (
            <Link to="/admin/manage-items">
              <img src={back} alt="back" />
            </Link>
          )}
          <h4 className="text-capitalize">{add_category.addNewCategoryText}</h4>
        </div>
      </div>
      <div className="cus-container admin-scroll">
        <div
          className="category-img"
          style={{
            backgroundImage: `url(${setDisplayImage})`,
            backgroundSize: "auto",
          }}
        >
          <div className="change-img">
            <img src={changeImg} alt="change-img" />
            <input
              accept="image/jpeg,image/png,image/bmp,image/tiff"
              type="file"
              ref={imageRef}
              onChange={(e) =>
                handleRegistrationInputChange(
                  "cat-image",
                  e.target.files[0],
                  true
                )
              }
            />
          </div>
        </div>
        <div className="floating-input">
          <label>{add_category.categoryNameLabel}</label>
          <input
            type="text"
            placeholder={add_category.enterCategoryNamePlaceholder}
            ref={categoryNameRef}
            className="floating-control"
            onChange={(e) =>
              handleRegistrationInputChange("name", e.target.value)
            }
          />
        </div>
      </div>
      <div className="bottom-btn">
        <div className="cus-container">
          <button
            onClick={handleAddCategory}
            type="button"
            className="border-0 common-btn"
            disabled={isAdding}
          >
            {isAdding ? (
              <>
                {add_category.addingButton} <MiniSpinnerComponent />
              </>
            ) : (
              add_category.saveButton
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;
