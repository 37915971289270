import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ToggleSwitch from "components/ToggleSwitch";
import Dropdown from "react-bootstrap/Dropdown";
import Search from "components/Search";
import back from "assets/images/arrow-left.svg";
import addItem from "assets/images/add-item-icon.svg";
import threeDots from "assets/images/three-dots.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  changeItemStatus,
  deleteItem,
  getItemByCategory,
} from "store/Features/Category/Category Items/categoryItemsServices";
import { showNotification } from "utils/customNotification";
import { SpinnerComponent } from "components/Spinner";
import {
  clearUpdateAddonList,
  clearUpdatedItems,
} from "store/Features/Category/Category Items/categoryItemsSlice";
import { usePageTitle } from "hooks/usePageTitle";
import { useLocale } from "localization/context";

const currency = process.env.REACT_APP_CURRENCY;
const CategoryItem = () => {
  const {
    state: { category_item },
  } = useLocale();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { itemsByCategory, categoryName, loading } = useSelector(
    (state) => state.items
  );
  const [searchItem, setSearchItem] = useState("");
  const [isToggleChanging, setIsToggleChanging] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  usePageTitle(category_item.manageItemTitle);

  useEffect(() => {
    dispatch(getItemByCategory({ category_id: id }));
    dispatch(clearUpdateAddonList());
    dispatch(clearUpdatedItems());
  }, [dispatch, id]);

  const filteredItems = itemsByCategory?.filter((item) => {
    const searchValue = searchItem.trim().toLowerCase();
    return (
      item.food_title.toLowerCase().includes(searchValue) ||
      item.second_title.toLowerCase().includes(searchValue)
    );
  });
  const renderedItems = searchItem ? filteredItems : itemsByCategory;

  const handleToggleChange = (item_id) => {
    if (isToggleChanging) return;
    setIsToggleChanging(true);
    dispatch(changeItemStatus({ item_id }))
      .unwrap()
      .then((promiseResult) => {
        if (!promiseResult) {
          showNotification("error", category_item.failStatusChangePrompt);
        }
      })
      .finally(() => setIsToggleChanging(false));
  };
  const handleDeleteItem = (item_id) => {
    if (isDeleting) return;
    setIsDeleting(true);
    dispatch(deleteItem({ item_id }))
      .unwrap()
      .then((promiseResult) => {
        if (promiseResult.status) {
          showNotification("success", category_item.deleteSuccessPrompt);
          dispatch(getItemByCategory({ category_id: id }));
        } else {
          showNotification("error", category_item.failDeletePrompt);
        }
      })
      .finally(() => setIsDeleting(false));
  };

  return (
    <div className="manage-items category-item h-100">
      <div className="page-title position-relative">
        <div className="cus-container d-flex justify-content-center">
          <Link to="/admin/manage-items">
            <img src={back} alt="back" />
          </Link>
          <h4 className="text-capitalize">{categoryName}</h4>
          {!loading && (
            <Link to={`/admin/add-category-item/${id}`}>
              <img src={addItem} alt="add-item" className="add-item-btn" />
            </Link>
          )}
        </div>
      </div>
      <div className="cus-container cat-item-inner">
        <Search state={searchItem} setState={setSearchItem} />
        <ul className="manage-item-list order-detail">
          {loading && <SpinnerComponent />}
          {renderedItems &&
            !loading &&
            renderedItems?.map((item) => (
              <li key={item.id}>
                <div className="table-box">
                  <div className="d-flex justify-content-between category-item-desc">
                    <div>
                      <h5>{item.food_title}</h5>
                      <p>{item.second_title ? `${item.second_title}` : ""}</p>
                    </div>
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <img src={threeDots} alt="three-dots" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            navigate(`/admin/update-category-item/${item.id}`)
                          }
                        >
                          {category_item.editDropdown}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleDeleteItem(item.id)}
                        >
                          {category_item.deleteDropdown}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p>
                      {currency}
                      {item.price}
                    </p>
                    <ToggleSwitch
                      isActive={item.status}
                      id={item.id}
                      handleToggleChange={handleToggleChange}
                      isToggleChanging={isToggleChanging}
                    />
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default CategoryItem;
