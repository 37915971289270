import { SpinnerComponent } from "components/Spinner";
import { useLocale } from "localization/context";
import { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerItemAddons } from "store/Features/Category/customer/customerCategoryService";
import {
  addAddon,
  clearAddonFromCartItem,
  removeAddon,
} from "store/Features/Category/customer/customerCategorySlice";

const currency = process.env.REACT_APP_CURRENCY;

const ItemsList = ({
  item,
  addonLoading,
  addonList,
  itemWithAddonState,
  setItemWithAddonState,
}) => {
  const dispatch = useDispatch();
  const { selectedAddons, cartItems } = useSelector(
    (state) => state.customerCategory
  );
  const [show, setShow] = useState(false);

  const handleCancle = () => setShow(false);
  const {
    state: { items_list },
  } = useLocale();

  const handleAddonAdd = () => {
    setShow(true);
    dispatch(getCustomerItemAddons({ item_id: item.id }));
  };

  function getItemWithAddon(addonId) {
    const currentItem = itemWithAddonState?.find(
      (currItem) => currItem.id === item.id
    );
    if (!addonId) {
      return {
        ...item,
        qty: 1,
        itemPricewithAddon: item.price,
      };
    }
    if (!currentItem) {
      const newAddon = {
        addon_id: addonId,
        addon_price: addonList.find((addon) => addon.id === addonId).price,
        addon_title: addonList.find((addon) => addon.id === addonId).title,
      };

      const addons = [newAddon];

      const selectedItemWithAddons = {
        ...item,
        addons,
        qty: 1,
        itemPricewithAddon: item.price,
      };
      return selectedItemWithAddons;
    } else {
      const isAddonAlreadyExists = currentItem.addons.some(
        (addon) => addon.addon_id === addonId
      );
      if (isAddonAlreadyExists) {
        const updatedAddons = currentItem.addons.filter(
          (addon) => addon.addon_id !== addonId
        );

        let newPrice = currentItem.price;
        if (updatedAddons.length > 0) {
          newPrice += updatedAddons
            .slice(1)
            .reduce((total, addon) => total + addon.addon_price, 0);
        }

        const selectedItemWithAddons = {
          ...currentItem,
          addons: updatedAddons,
          qty: 1,
          itemPricewithAddon: newPrice,
        };
        return selectedItemWithAddons;
      } else {
        const newAddon = {
          addon_id: addonId,
          addon_price: addonList.find((addon) => addon.id === addonId).price,
          addon_title: addonList.find((addon) => addon.id === addonId).title,
        };
        const newPrice = currentItem.itemPricewithAddon + newAddon.addon_price;

        const selectedItemWithAddons = {
          ...currentItem,
          addons: [...currentItem.addons, newAddon],
          qty: 1,
          itemPricewithAddon: newPrice,
        };
        return selectedItemWithAddons;
      }
    }
  }

  const handleClose = () => {
    if (addonList.length === 0) {
      const itemWithoutAddon = getItemWithAddon();
      setItemWithAddonState((prevState) => {
        if (!prevState) {
          return [itemWithoutAddon];
        } else {
          const index = prevState.findIndex(
            (prevItem) => prevItem.id === itemWithoutAddon.id
          );
          if (index !== -1) {
            const updatedState = [...prevState];
            updatedState[index] = itemWithoutAddon;
            return updatedState;
          } else {
            return [...prevState, itemWithoutAddon];
          }
        }
      });
    }
    setShow(false);
  };

  const handleRadioInputChange = (addonId) => {
    dispatch(addAddon({ itemId: item.id, addonId }));
    const itemWithAddon = getItemWithAddon(addonId);

    setItemWithAddonState((prevState) => {
      if (!prevState) {
        return [itemWithAddon];
      } else {
        const index = prevState.findIndex(
          (prevItem) => prevItem.id === itemWithAddon.id
        );
        if (itemWithAddon.addons.length === 0) {
          if (index !== -1) {
            const updatedState = [...prevState];
            updatedState.splice(index, 1);
            return updatedState;
          }
        } else if (index !== -1) {
          const updatedState = [...prevState];
          updatedState[index] = itemWithAddon;
          return updatedState;
        } else {
          return [...prevState, itemWithAddon];
        }
      }
    });
  };

  const handleClearAddon = (itemId) => {
    dispatch(removeAddon(itemId));
    if (cartItems.items) {
      dispatch(clearAddonFromCartItem(itemId));
      dispatch(removeAddon(itemId));
    }
    setItemWithAddonState((prevState) => {
      const clearAddonFromCurrentItem = prevState.find(
        (prevItem) => itemId === prevItem.id
      );

      if (clearAddonFromCurrentItem) {
        return prevState.map((item) =>
          item.id === clearAddonFromCurrentItem.id
            ? { ...item, addons: [] }
            : item
        );
      }
      return prevState;
    });
  };

  return (
    <li className="d-flex justify-content-between" key={item.id}>
      <div className="item-desc">
        <h5>{item.food_title}</h5>
        <p>{item.second_title ? item.second_title : " "}</p>
        {/* <span>{currency}0</span> */}
        <span>
          {currency}
          {item.price}
        </span>
      </div>
      <div className="add-remove-qty">
        <div className="d-flex justify-content-center align-items-center">
          <button
            variant="primary"
            onClick={handleAddonAdd}
            className="p-0 border-0 d-flex align-items-center justify-content-center"
          >
            +
          </button>
        </div>
      </div>
      <Offcanvas
        show={show}
        onHide={handleCancle}
        placement="bottom"
        className="menu-canvas"
      >
        <Offcanvas.Header closeButton>
          <h4 className="text-capitalize">{items_list.addonsText}</h4>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="addons-list">
            {addonLoading && <SpinnerComponent />}
            {!addonLoading && addonList.length === 0 && (
              <p>{items_list.noAddonText}</p>
            )}
            {!addonLoading &&
              addonList.length > 0 &&
              addonList.map((addon, index) => {
                const isSelected = selectedAddons.some((selectedAddon) =>
                  selectedAddon.addons.includes(addon.id)
                );

                const selectedItemWithAddon = selectedAddons?.find(
                  (itmId) => itmId.itemId === item.id
                );
                const isAnyAddonSelected =
                  selectedItemWithAddon &&
                  selectedItemWithAddon.addons.length > 0;

                let addonPrice = isAnyAddonSelected
                  ? `${currency}${addon.price}`
                  : `${currency}0`;

                if (
                  isAnyAddonSelected &&
                  addon.id === selectedItemWithAddon.addons[0]
                ) {
                  addonPrice = `${currency}0`;
                }
                return (
                  <li
                    className="d-flex align-items-center justify-content-between"
                    key={addon.id}
                  >
                    <label className="cus-radio position-relative">
                      {addon.title}
                      <input
                        type="checkbox"
                        name="checkbox"
                        onChange={() => handleRadioInputChange(addon.id)}
                        checked={isSelected}
                      />
                      <span className="radio-bg"></span>
                      <span className="radio-chk"></span>
                    </label>
                    <p>{addonPrice}</p>
                  </li>
                );
              })}
          </ul>
          {addonList.length > 0 && (
            <p
              className="text-center mt-3"
              onClick={() => handleClearAddon(item.id)}
            >
              {items_list.clearAddonText}
            </p>
          )}
          <button
            type="button"
            onClick={handleClose}
            className="common-btn border-0"
          >
            {items_list.saveAndContinue}
          </button>
        </Offcanvas.Body>
      </Offcanvas>
    </li>
  );
};

export default ItemsList;
