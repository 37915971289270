import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import back from "assets/images/arrow-left.svg";
import { useDispatch, useSelector } from "react-redux";
import { showNotification } from "utils/customNotification";
import {
  getSingleTable,
  updateTable,
} from "store/Features/ManageTable/manageTableServices";
import { usePageTitle } from "hooks/usePageTitle";
import { MiniSpinnerComponent, SpinnerComponent } from "components/Spinner";
import { useLocale } from "localization/context";

const UpdateTable = () => {
  const {
    state: { update_table },
  } = useLocale();
  const dispatch = useDispatch();
  const { tables } = useSelector((state) => state.tables);
  const navigate = useNavigate();
  const { id } = useParams();
  const [tableName, setTableName] = useState("");
  const [loading, setLoading] = useState(true);
  const [isPending, setIsPending] = useState(false);
  usePageTitle(update_table.updateTableTitle);

  useEffect(() => {
    if (tables) {
      const currentTable = tables.find((table) => table.id === parseInt(id));
      if (currentTable) {
        setLoading(false);
        setTableName(currentTable?.name);
      } else {
        dispatch(getSingleTable({ table_id: id }))
          .unwrap()
          .then((promiseResult) => {
            if (promiseResult.status) {
              setTableName(promiseResult.data.name);
            }
          })
          .finally(() => setLoading(false));
      }
    }
  }, [dispatch, id, tables]);

  const handleTableSubmit = (event) => {
    event.preventDefault();
    const name = tableName;
    setIsPending(true);
    dispatch(updateTable({ table_id: id, name }))
      .unwrap()
      .then((promiseResult) => {
        if (promiseResult.status) {
          showNotification("success", update_table.tableUpdatedSuccssPrompt);
          navigate("/admin/manage-tables");
        } else {
          showNotification("error", update_table.failUpdateTablePrompt);
        }
      })
      .finally(() => setTimeout(() => setIsPending(false)), 1000);
  };
  return (
    <div className="add-category add-category-item h-100">
      <div className="page-title position-relative">
        <div className="cus-container d-flex justify-content-center">
          {!isPending && (
            <Link to="/admin/manage-tables">
              <img src={back} alt="back" />
            </Link>
          )}
          <h4 className="text-capitalize">{update_table.updateTable}</h4>
        </div>
      </div>
      {loading && <SpinnerComponent />}
      {!loading && (
        <form onSubmit={handleTableSubmit} className="h-100">
          <div className="cus-container admin-scroll">
            <div className="floating-input">
              <label>{update_table.inputForm.nameLabel}</label>
              <input
                type="text"
                placeholder={update_table.inputForm.enterNamePlaceholder}
                className="floating-control"
                value={tableName}
                onChange={(e) => setTableName(e.target.value)}
              />
            </div>
          </div>
          <div className="bottom-btn">
            <div className="cus-container">
              <button
                type="submit"
                className="border-0 common-btn"
                disabled={isPending}
              >
                {isPending ? (
                  <>
                    {update_table.inputForm.updatingButton}{" "}
                    <MiniSpinnerComponent />
                  </>
                ) : (
                  update_table.inputForm.updateAndSaveButton
                )}
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default UpdateTable;
