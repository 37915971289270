import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeState: "",
};

const footerSlice = createSlice({
  name: "activeState",
  initialState,
  reducers: {
    setActiveState: (state, { payload }) => {
      state.activeState = payload;
    },
  },
});
export const { setActiveState } = footerSlice.actions;
export default footerSlice.reducer;
