import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import back from "assets/images/arrow-left.svg";
import addCategoryImage from "assets/images/add-category.jpg";
import changeImg from "assets/images/change-img.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleCategory,
  updateCategory,
} from "store/Features/Category/categoryServices";
import { showNotification } from "utils/customNotification";
import validateFileType from "utils/validateFileType";
import { usePageTitle } from "hooks/usePageTitle";
import { MiniSpinnerComponent } from "components/Spinner";
import { useLocale } from "localization/context";

const AddCategory = () => {
  const {
    state: { update_category },
  } = useLocale();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categoryList } = useSelector((state) => state.category);
  const { id } = useParams();
  const imageRef = useRef();
  const [selectedImage, setSelectedImage] = useState();
  const [updateTitle, setUpdateTitle] = useState("");
  const [loading, setIsLoading] = useState(true);
  const [isPending, setIsPending] = useState(false);
  usePageTitle(update_category.updateCategoryTitle);

  useEffect(() => {
    const currentCategory = categoryList?.find(
      (category) => category.id === parseInt(id)
    );
    if (currentCategory) {
      setUpdateTitle(currentCategory.name);
      setSelectedImage(currentCategory.image);
      setIsLoading(false);
    }
    dispatch(getSingleCategory({ category_id: id }))
      .unwrap()
      .then((promiseResult) => {
        if (promiseResult.status) {
          setUpdateTitle(promiseResult?.data?.name);
          setSelectedImage(promiseResult?.data.image);
        }
      });
  }, [categoryList, dispatch, id]);

  const handleRegistrationInputChange = async (name, value, isFileControl) => {
    if (isFileControl) {
      if (name === "updated-image") {
        const file = imageRef.current.files[0];
        const reader = new FileReader();
        if (!validateFileType(file.type)) {
          showNotification("error", update_category.onlySelectImageFilePrompt);
          return;
        }
        reader.onload = () => {
          setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
      return;
    }
  };

  const handleUpdateCategory = () => {
    const image = imageRef.current.files[0];

    const formdata = new FormData();
    formdata.append("category_id", id);
    formdata.append("name", updateTitle);
    if (image) {
      formdata.append("image", image);
    }
    setIsPending(true);
    dispatch(updateCategory(formdata))
      .unwrap()
      .then((promiseResult) => {
        if (promiseResult.status) {
          showNotification("success", update_category.categoryUpdatedPrompt);
          navigate("/admin/manage-items");
        } else {
          showNotification("error", update_category.failedUpdatePrompt);
        }
      })
      .finally(() => setTimeout(() => setIsPending(false), 1000));
  };

  const setDisplayImage = selectedImage ? selectedImage : addCategoryImage;

  return (
    <div className="add-category h-100">
      <div className="page-title position-relative">
        <div className="cus-container d-flex justify-content-center">
          {!isPending && (
            <Link to="/admin/manage-items">
              <img src={back} alt="back" />
            </Link>
          )}
          <h4 className="text-capitalize">{update_category.updateCategory}</h4>
        </div>
      </div>
      {!loading && (
        <div className="cus-container admin-scroll">
          <div
            className="category-img"
            style={{
              backgroundImage: `url(${setDisplayImage})`,
            }}
          >
            <div className="change-img">
              <img src={changeImg} alt="change-img" />
              <input
                accept="image/jpeg,image/png,image/bmp,image/tiff"
                type="file"
                ref={imageRef}
                onChange={(e) =>
                  handleRegistrationInputChange(
                    "updated-image",
                    e.target.files[0],
                    true
                  )
                }
              />
            </div>
          </div>
          <div className="floating-input">
            <label>{update_category.categoryNameLabel}</label>
            <input
              type="text"
              placeholder={update_category.enterCategoryNamePlaceholder}
              value={updateTitle}
              className="floating-control"
              onChange={(e) => setUpdateTitle(e.target.value)}
            />
          </div>
        </div>
      )}
      {!loading && (
        <div className="bottom-btn">
          <div className="cus-container">
            <button
              onClick={handleUpdateCategory}
              type="button"
              className="border-0 common-btn"
            >
              {isPending ? (
                <>
                  {update_category.updatingButton} <MiniSpinnerComponent />
                </>
              ) : (
                update_category.updateAndSaveButton
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddCategory;
