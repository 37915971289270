import React, { useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import back from "assets/images/arrow-left.svg";
import { showNotification } from "utils/customNotification";
import { useDispatch, useSelector } from "react-redux";
import { addonList } from "store/Features/Category/Category Items/categoryItemsSlice";
import { addAddon } from "store/Features/Category/Category Items/categoryItemsServices";
import { usePageTitle } from "hooks/usePageTitle";
import { MiniSpinnerComponent } from "components/Spinner";
import { useLocale } from "localization/context";

const AddAddon = () => {
  const {
    state: { add_addon },
  } = useLocale();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { updatedItem } = useSelector((state) => state.items);
  const nameRef = useRef();
  const priceRef = useRef();
  const [isAddingAddon, setIsAddingAddon] = useState(false);
  usePageTitle(add_addon.addAddonTitle);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isAddingAddon) return;
    const name = nameRef.current.value;
    const price = priceRef.current.value;

    if (!name || !price) {
      showNotification("error", add_addon.enterBothFieldPrompt);
      return;
    }
    setIsAddingAddon(true);
    dispatch(addAddon({ item_id: id, title: name, price }))
      .unwrap()
      .then(() => {
        if (updatedItem && updatedItem.category_id) {
          setTimeout(() => {
            navigate(`/admin/update-category-item/${id}`);
          }, 1000);
        } else {
          dispatch(addonList({ title: name, price }));
          setTimeout(() => {
            navigate(`/admin/add-category-item/${id}`);
          }, 1000);
        }
      })
      .finally(() => setIsAddingAddon(false));
  };

  return (
    <div className="add-category add-category-item h-100">
      <div className="page-title position-relative">
        <div className="cus-container d-flex justify-content-center">
          {!isAddingAddon &&
            (updatedItem && updatedItem?.category_id ? (
              <Link to={`/admin/update-category-item/${id}`}>
                <img src={back} alt="back" />
              </Link>
            ) : (
              <Link to={`/admin/add-category-item/${id}`}>
                <img src={back} alt="back" />
              </Link>
            ))}
          <h4 className="text-capitalize">{add_addon.addNewAddon}</h4>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="h-100">
        <div className="cus-container admin-scroll">
          <div className="floating-input">
            <label>{add_addon.nameLabel}</label>
            <input
              type="text"
              placeholder={add_addon.enterNamePlaceholder}
              ref={nameRef}
              className="floating-control"
            />
          </div>
          <div className="floating-input">
            <label>{add_addon.priceLabel}</label>
            <input
              type="text"
              placeholder={add_addon.enterPricePlaceholder}
              ref={priceRef}
              className="floating-control"
            />
          </div>
        </div>
        <div className="bottom-btn">
          <div className="cus-container">
            <button
              type="submit"
              className="border-0 common-btn"
              disabled={isAddingAddon}
            >
              {isAddingAddon ? (
                <>
                  {add_addon.addingButton} <MiniSpinnerComponent />
                </>
              ) : (
                add_addon.saveButton
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddAddon;
