import React, { useEffect } from "react";
import logo from "assets/images/logo.png";
import userImg from "assets/images/user-img.jpg";
import { Link } from "react-router-dom";
import Footer from "components/Footer";
import orderImg from "assets/images/order-img.png";
import noOrder from "assets/images/no-order.svg";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { usePageTitle } from "hooks/usePageTitle";
import { useDispatch, useSelector } from "react-redux";
import { setActiveState } from "store/Features/Footer/footerSlice";
import { getOrders } from "store/Features/Orders/ordersServices";
import { useState } from "react";
import { SpinnerComponent } from "components/Spinner";
import { formateDate } from "utils/FormateData";
import { useLocale } from "localization/context";

const currency = process.env.REACT_APP_CURRENCY;
function Home() {
  const {
    state: { home },
  } = useLocale();
  const [activeTab, setActiveTab] = useState("active");
  const dispatch = useDispatch();
  const { orders, loading } = useSelector((state) => state.orders);
  const {
    loading: adminDataLoading,
    adminData: { profile_pic },
    isUpdatedImage,
    updated_image_url,
  } = useSelector((state) => state.auth);
  usePageTitle(home.adminHomeTitle);

  useEffect(() => {
    dispatch(setActiveState("orders"));
    dispatch(getOrders({ status: activeTab }));
  }, [dispatch, activeTab]);

  const handleTabChange = (tabKey) => {
    if (tabKey === "home") {
      setActiveTab("active");
    } else if (tabKey === "profile") {
      setActiveTab("history");
    }
  };
  const profileImage =
    !adminDataLoading && (isUpdatedImage ? updated_image_url : profile_pic);
  return (
    <div className="home-page h-100">
      <div className="cus-container h-100">
        <div className="admin-header position-relative">
          <div className="d-flex justify-content-center">
            <img src={logo} alt="logo" />
            {!adminDataLoading && (
              <Link
                to="/admin/profile"
                className="user-img"
                style={{
                  backgroundImage: `url(${
                    profileImage ? profileImage : userImg
                  })`,
                }}
              ></Link>
            )}
          </div>
        </div>
        <Tabs
          defaultActiveKey="home"
          id="uncontrolled-tab-example"
          className="order-tab"
          onSelect={handleTabChange}
        >
          <Tab eventKey="home" title={home.activeOrdersTitle}>
            {loading && <SpinnerComponent />}
            {!loading && orders?.length === 0 && (
              <div className="d-flex flex-column justify-content-center no-order">
                <img src={noOrder} alt="no-order" />
                <p>{home.noActiveOrdersFound}</p>
              </div>
            )}
            {orders &&
              !loading &&
              orders?.map((order) => {
                const date = formateDate(order.created_at);
                if (order.status === "Preparing" || order.status === "Placed") {
                  return (
                    <Link
                      to={`/admin/order-detail/${order.id}`}
                      className="myorderd-list"
                      key={order.id}
                    >
                      <div className="orderid-title d-flex justify-content-between">
                        <div className="d-flex">
                          <div className="order-img">
                            <img src={orderImg} alt="order" />
                          </div>
                          <div className="order-price">
                            <h5>#{order.order_code}</h5>
                            <p>
                              {currency}
                              {order.total_amount}
                            </p>
                          </div>
                        </div>
                        <div>
                          <p
                            className={`order-status ${
                              order.status === "Preparing" ? "preparing" : ""
                            }`}
                          >
                            {order.status}
                          </p>
                        </div>
                      </div>
                      <div className="ordered-item">
                        {order.items.length === 0 && (
                          <p>{home.noOrderItemText}</p>
                        )}
                        {order.items.length >= 1 &&
                          order.items?.map((item, index) => (
                            <p key={index}>
                              {item.food_title} X {item.qty}
                            </p>
                          ))}
                      </div>
                      <div className="ordered-table d-flex justify-content-between">
                        <p>#{order.table_name}</p>
                        <p>
                          {date.formattedDate} {date.formattedTime}
                        </p>
                      </div>
                    </Link>
                  );
                }
                return null;
              })}
          </Tab>
          <Tab eventKey="profile" title={home.orderHistoryTitle}>
            {loading && <SpinnerComponent />}
            {!loading && orders?.length === 0 && (
              <div className="d-flex flex-column justify-content-center no-order">
                <img src={noOrder} alt="no-order" />
                <p>{home.noOrderHistoryFound}</p>
              </div>
            )}
            {orders &&
              !loading &&
              orders?.map((order) => {
                const date = formateDate(order.created_at);
                if (order.status === "Served") {
                  return (
                    <Link
                      to="/admin/home"
                      className="myorderd-list"
                      key={order.id}
                    >
                      <div className="orderid-title d-flex justify-content-between">
                        <div className="d-flex">
                          <div className="order-img">
                            <img src={orderImg} alt="order" />
                          </div>
                          <div className="order-price">
                            <h5>#{order.order_code}</h5>
                            <p>
                              {currency}
                              {order.total_amount}
                            </p>
                          </div>
                        </div>
                        <div>
                          <p
                            className={`order-status ${
                              order.status === "Served" ? "served" : ""
                            }`}
                          >
                            {order.status}
                          </p>
                        </div>
                      </div>
                      <div className="ordered-item">
                        {order.items?.map((item, index) => (
                          <p key={index}>
                            {item.food_title} X {item.qty}
                          </p>
                        ))}
                      </div>
                      <div className="ordered-table d-flex justify-content-between">
                        <p>#{order.table_name}</p>
                        <p>
                          {date.formattedDate} {date.formattedTime}
                        </p>
                      </div>
                    </Link>
                  );
                }
                return null;
              })}
          </Tab>
        </Tabs>
      </div>
      <Footer isAdmin={true} />
    </div>
  );
}
export default Home;
