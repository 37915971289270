import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import back from "assets/images/arrow-left.svg";
import addonIcon from "assets/images/add-addon-icon.svg";
import {
  deleteAddon,
  getSingleItem,
  updateSingleItem,
} from "store/Features/Category/Category Items/categoryItemsServices";
import { useDispatch, useSelector } from "react-redux";
import { showNotification } from "utils/customNotification";
import {
  handleUpdateItem,
  clearUpdateAddonList,
  clearUpdatedItems,
  handleEditAddon,
} from "store/Features/Category/Category Items/categoryItemsSlice";
import AddonCheckbox from "components/AddonCheckbox";
import { usePageTitle } from "hooks/usePageTitle";
import { MiniSpinnerComponent, SpinnerComponent } from "components/Spinner";
import { useLocale } from "localization/context";

const UpdateCategoryItem = () => {
  const {
    state: { update_category_item },
  } = useLocale();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categoryList } = useSelector((state) => state.category);
  const { updateAddonList: addon, updatedItem } = useSelector(
    (state) => state.items
  );
  const [selectedCategory, setSelectedCategory] = useState();
  const [changeAddonState, setChangeAddonState] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isAddonDeleting, setIsAddonDeleting] = useState(false);

  usePageTitle(update_category_item.updateItemTitle);

  useEffect(() => {
    dispatch(getSingleItem({ item_id: id }))
      .unwrap()
      .then((promiseResult) => {
        if (promiseResult.status) {
          setSelectedCategory(promiseResult?.data?.category_id);
          setChangeAddonState(promiseResult?.data?.addons);
        }
      })
      .finally(() => setIsLoading(false));
  }, [dispatch, id]);

  const handleRegistrationInputChange = (name, value) => {
    if (name === "category_id") {
      setSelectedCategory(value);
    }
    dispatch(handleUpdateItem({ [name]: value }));
  };

  const handleAddCategory = (event) => {
    event.preventDefault();
    if (isUpdating) return;
    console.log(updatedItem);
    if (!updatedItem.food_title || !updatedItem.price) {
      showNotification("error", update_category_item.addAllFieldsPrompt);
      return;
    }
    const priceRegex = /^\d+([.,]\d{1,2})?$/;
    const isValidPrice = priceRegex.test(parseInt(updatedItem.price));
    if (!isValidPrice) {
      showNotification("error", update_category_item.validPricePrompt);
      return;
    }

    const data = {
      item_id: parseInt(id),
      category_id: updatedItem.category_id,
      food_title: updatedItem.food_title,
      second_title: updatedItem.second_title,
      price: updatedItem.price,
      addons: JSON.stringify(changeAddonState),
    };
    setIsUpdating(true);

    dispatch(updateSingleItem(data))
      .unwrap()
      .then((promiseResult) => {
        if (promiseResult.status) {
          showNotification(
            "success",
            update_category_item.itemUpdateSuccessPrompt
          );
          navigate(`/admin/category-item/${promiseResult?.data.category_id}`);
          dispatch(clearUpdateAddonList());
          dispatch(clearUpdatedItems());
        } else {
          showNotification("error", update_category_item.failUpdateItemPrompt);
        }
      })
      .finally(() => setIsUpdating(false));
  };
  const handleDeleteAddon = (addon_id) => {
    if (isAddonDeleting) return;

    setIsAddonDeleting(true);
    dispatch(deleteAddon({ addon_id }))
      .unwrap()
      .then((promiseResult) => {
        if (promiseResult.status) {
          dispatch(getSingleItem({ item_id: id }));
        }
      })
      .finally(() => setIsAddonDeleting(false));
  };
  const handleUpdateAddon = (addonId) => {
    dispatch(handleEditAddon({ category_id: id }));
    navigate(`/admin/update-addon/${addonId}`);
  };
  const handleStatusChange = (addOnId, status) => {
    if (changeAddonState) {
      setChangeAddonState((prevState) =>
        prevState.map((addon) =>
          addon.id === addOnId
            ? { ...addon, status: status ? "Active" : "Inactive" }
            : addon
        )
      );
    }
  };
  const handleGoBack = () => {
    if (updatedItem.category_id) {
      navigate(`/admin/category-item/${updatedItem.category_id}`);
    } else {
      navigate("/admin/manage-items");
      showNotification("error", update_category_item.failedGettingCategoryId);
    }
  };

  return (
    <div className="add-category add-category-item h-100">
      <form onSubmit={handleAddCategory} className="h-100">
        <div className="page-title position-relative">
          <div className="cus-container d-flex justify-content-center">
            {!isLoading && !isUpdating && (
              <span onClick={handleGoBack}>
                <img src={back} alt="back" />
              </span>
            )}
            <h4 className="text-capitalize">
              {update_category_item.updateItem}
            </h4>
          </div>
        </div>
        {isLoading ? (
          <SpinnerComponent />
        ) : (
          <>
            {" "}
            <div className="cus-container admin-scroll">
              <div className="floating-input">
                <label>{update_category_item.labelSelectCategory}</label>
                <select
                  className="floating-control"
                  value={selectedCategory}
                  onChange={(e) =>
                    handleRegistrationInputChange("category_id", e.target.value)
                  }
                >
                  {categoryList &&
                    categoryList.map((category) => (
                      <option value={category.id} key={category.id}>
                        {category.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="floating-input">
                <label>{update_category_item.foodTitleLabel}</label>
                <input
                  type="text"
                  placeholder={update_category_item.enterFoodTitlePlaceholder}
                  className="floating-control"
                  defaultValue={updatedItem.food_title}
                  onInput={(e) =>
                    handleRegistrationInputChange("food_title", e.target.value)
                  }
                />
              </div>
              <div className="floating-input">
                <label>{update_category_item.secondTitle}</label>
                <textarea
                  className="floating-control big-height"
                  placeholder={update_category_item.enterSecondTitlePlaceholder}
                  defaultValue={updatedItem.second_title}
                  onInput={(e) =>
                    handleRegistrationInputChange(
                      "second_title",
                      e.target.value
                    )
                  }
                ></textarea>
              </div>
              <div className="floating-input">
                <label>{update_category_item.priceLabel}</label>
                <input
                  type="text"
                  placeholder={update_category_item.enterPricePlaceholder}
                  className="floating-control"
                  defaultValue={updatedItem.price}
                  onChange={(e) =>
                    handleRegistrationInputChange("price", e.target.value)
                  }
                />
              </div>
              <div className="select-addons">
                <h5>{update_category_item.selectAddonHeading}</h5>
                <ul className="addons-list">
                  {addon &&
                    addon.length > 0 &&
                    addon.map((addOn, index) => (
                      <AddonCheckbox
                        key={addOn.id}
                        addOn={addOn}
                        handleUpdateAddon={handleUpdateAddon}
                        handleDeleteAddon={handleDeleteAddon}
                        handleStatusChange={handleStatusChange}
                      />
                    ))}
                </ul>
                <Link
                  to={`/admin/add-addon/${id}`}
                  className="common-link d-flex align-items-center"
                >
                  <img src={addonIcon} alt="add-addon" />
                  &nbsp;&nbsp; {update_category_item.addNewAddonText}
                </Link>
              </div>
            </div>
            <div className="bottom-btn">
              <div className="cus-container">
                <button
                  type="submit"
                  className="border-0 common-btn"
                  disabled={isUpdating}
                >
                  {isUpdating ? (
                    <>
                      {update_category_item.updatingButton}{" "}
                      <MiniSpinnerComponent />
                    </>
                  ) : (
                    update_category_item.updateButton
                  )}
                </button>
              </div>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default UpdateCategoryItem;
