import React, { useCallback, useLayoutEffect, useState } from "react";

const ToggleSwitch = ({
  isActive,
  id,
  handleToggleChange,
  isToggleChanging,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  useLayoutEffect(() => {
    setIsChecked(isActive === "Active");
  }, [isActive]);

  const onToggleChange = useCallback(() => {
    if (isToggleChanging) {
      return;
    }
    setIsChecked(!isChecked);
    handleToggleChange(id);
  }, [handleToggleChange, id, isChecked, isToggleChanging]);

  return (
    <div className="toggle-btn position-relative">
      <input type="checkbox" checked={isChecked} onChange={onToggleChange} />
      <span className="toggle-bg"></span>
      <span className="toggle"></span>
    </div>
  );
};

export default ToggleSwitch;
