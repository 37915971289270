import React from "react";
import logo from "assets/images/logo.png";
import { Link } from "react-router-dom";
import { usePageTitle } from "hooks/usePageTitle";
import { useLocale } from "localization/context";

const OrderPlaced = () => {
  const {
    state: { order_placed },
  } = useLocale();
  usePageTitle(order_placed.orderPlacedTitle);
  return (
    <div className="order-placed">
      <div className="cus-container">
        <div className="order-placed-dtl">
          <div className="text-center">
            <Link to="/menu">
              <img src={logo} alt="logo" />
            </Link>
            <h1>{order_placed.ordernErAfgivetText}</h1>
            <h4>{order_placed.takeOrdersText}</h4>
          </div>
        </div>
      </div>
      <div className="cus-container">
        <Link to="/menu" className="common-btn">
          {order_placed.goHomeText}
        </Link>
      </div>
    </div>
  );
};
export default OrderPlaced;
