import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import authReducer from "store/Features/Auth/Admin/authSlice";
import loadingReducer from "store/Features/Loading/LoadingSlice";
import footerReducer from "store/Features/Footer/footerSlice";
import categoryReducer from "store/Features/Category/categorySlice";
import manageTableReducer from "store/Features/ManageTable/manageTableSlice";
import itemByCategoryReducer from "store/Features/Category/Category Items/categoryItemsSlice";
import ordersReducer from "store/Features/Orders/ordersSlice";
import customerCategoryReducer from "store/Features/Category/customer/customerCategorySlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const reducer = combineReducers({
  auth: authReducer,
  isLoading: loadingReducer,
  activeState: footerReducer,
  category: categoryReducer,
  tables: manageTableReducer,
  items: itemByCategoryReducer,
  orders: ordersReducer,
  customerCategory: customerCategoryReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
