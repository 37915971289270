import React, { useEffect, useState } from "react";
import logo from "assets/images/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showNotification } from "utils/customNotification";
import { getCustomerToken } from "store/Features/Category/customer/customerCategoryService";

const SplashScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState();
  const [loading, setLoading] = useState(true);
  const location = useLocation(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const tableId = queryParams.get("table_id");
    const tableCode = queryParams.get("table_code");

    if (!tableId || !tableCode) {
      setTableData(false);
    }
    dispatch(getCustomerToken())
      .unwrap()
      .then((promiseResult) => {
        if (promiseResult.status) {
          localStorage.setItem("_apric_customer_token", promiseResult.data);
        } else {
          showNotification("error", "something went wrong");
        }
      })
      .finally(() => setLoading(false));
    if (tableId && tableCode) {
      setTableData(true);
      localStorage.setItem("table_id", JSON.stringify(tableId));
      localStorage.setItem("table_code", JSON.stringify(tableCode));
    }
  }, [dispatch, location.search]);

  useEffect(() => {
    if (!loading && tableData) {
      navigate("/menu");
    }
  }, [loading, tableData, navigate]);

  return (
    <div className="splash-screen ">
      <div className="text-center">
        <img src={logo} alt="logo" />
      </div>
    </div>
  );
};

export default SplashScreen;
