import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "utils/axiosInstance";

const backendURL = process.env.REACT_APP_API_BASE_URL;

export const adminLogin = createAsyncThunk(
  "admin/login",
  async (
    { email, password, device_token, device_type },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axiosInstance.post(
        `/login`,
        {
          email,
          password,
          device_token,
          device_type,
        },
        config
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const adminChangeProfileImage = createAsyncThunk(
  "admin/change-profile-image",
  async ({ profile_pic }, { rejectWithValue }) => {
    try {
      const config = {
        method: "POST",
        redirect: "follow",
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_admin_token"
          )} `,
        },
      };

      let formdata = new FormData();
      formdata.append("profile_pic", profile_pic);

      let requestOptions = {
        method: config.method,
        headers: config.headers,
        body: formdata,
        redirect: config.redirect,
      };

      const data = await fetch(
        `${backendURL}/update-profile-pic`,
        requestOptions
      );
      if (data.status === 401) {
        window.localStorage.clear();
        window.location.href = `/admin/login`;
      }

      return data.json();
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const adminChangePassword = createAsyncThunk(
  "admin/change-password",
  async (
    { old_password, new_password, confirm_password },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_admin_token"
          )} `,
        },
      };
      const { data } = await axiosInstance.post(
        `/change-password`,
        {
          old_password,
          new_password,
          confirm_password,
        },
        config
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const adminForgotPassword = createAsyncThunk(
  "admin/forgot-password",
  async ({ email }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axiosInstance.post(
        `/forgot-password`,
        {
          email,
        },
        config
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const adminOtpVerify = createAsyncThunk(
  "admin/otp-verify",
  async ({ email, otp }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axiosInstance.post(
        `/forgot-otp-verify`,
        {
          email,
          otp,
        },
        config
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const adminResendOtp = createAsyncThunk(
  "admin/resend-otp",
  async ({ email }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axiosInstance.post(
        `/resend-forgot-otp`,
        {
          email,
        },
        config
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const adminResetPassword = createAsyncThunk(
  "admin/reset-password",
  async ({ token, password, confirm_password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axiosInstance.post(
        `/reset-password`,
        {
          token,
          password,
          confirm_password,
        },
        config
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const adminLogout = createAsyncThunk(
  "admin/reset-password",
  async (_, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_admin_token"
          )} `,
        },
      };
      const { data } = await axiosInstance.post(`/logout`, {}, config);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);
