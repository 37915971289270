import { createSlice } from "@reduxjs/toolkit";
import { getOrders } from './ordersServices';

const initialState = {
  loading: false,
  error: null,
  message: "",
  orders: [],
};

const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    clearCurrentOrder: (state) => {
      state.currentOrder = {};
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action.payload?.data;
      })
      .addCase(getOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});
export const { selectedCategory } = orderSlice.actions;
export default orderSlice.reducer;
