import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import SplashScreen from "pages/client/SplashScreen";
import Menu from "pages/client/Menu";
import PlaceOrder from "pages/client/PlaceOrder";
import OrderPlaced from "pages/client/OrderPlaced";
import MyOrders from "pages/client/MyOrders";
import OrderDetail from "pages/client/OrderDetail";
import Waiter from "pages/client/Waiter";
import Search from "pages/client/SearchPage";
import Login from "pages/admin/Login";
import ForgotPassword from "pages/admin/ForgotPassword";
import Home from "pages/admin/Home";
import AdminOrderDetail from "pages/admin/AdminOrderDetail";
import Profile from "pages/admin/Profile";
import ChangePassword from "pages/admin/ChangePassword";
import ManageItems from "pages/admin/ManageItems";
import AddCategory from "pages/admin/AddCategory";
import UpdateCategory from "pages/admin/UpdateCategory";
import CategoryItem from "pages/admin/CategoryItem";
import AddCategoryItem from "pages/admin/AddCategoryItem";
import AddAddon from "pages/admin/AddAddon";
import ManageTables from "pages/admin/ManageTables";
import AddTable from "pages/admin/AddTable";
import ResetPassword from "pages/admin/ResetPassword";
import OtpVarification from "pages/admin/OptVarification";
import UpdateTable from "pages/admin/UpdateTable";
import UpdateCategoryItem from "pages/admin/UpdateCategoryItem";
import UpdateAddon from "pages/admin/UpdateAddon";

const ProtectedRoute = () => {
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const isAdmin = isLoggedIn && localStorage.getItem("_apric_admin_token");

  return !isAdmin ? <Navigate to="/admin/login" /> : <Outlet />;
};

const AuthRoute = () => {
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const isAdmin = isLoggedIn && localStorage.getItem("_apric_admin_token");

  return isAdmin ? <Navigate to="/admin/home" /> : <Outlet />;
};

const ClientProtectedRoute = () => {
  const tableCode = localStorage.getItem("table_code");
  const tableId = localStorage.getItem("table_id");
  const customerToken = localStorage.getItem("_apric_customer_token");

  return !tableCode || !tableId || !customerToken ? (
    <Navigate to="/" />
  ) : (
    <Outlet />
  );
};

const AppRoutes = () => {
  return (
    <Routes>
      <Route>
        {/* admin routes */}
        <Route element={<AuthRoute />}>
          <Route path="/admin/login" element={<Login />} />
          <Route path="/admin/forgot-password" element={<ForgotPassword />} />
          <Route path="/admin/otp-verify" element={<OtpVarification />} />
          <Route path="/admin/reset-password" element={<ResetPassword />} />
          <Route path="/admin/change-password" element={<ChangePassword />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route exact path="/admin/home" element={<Home />} />
          <Route exact path="/admin/profile" element={<Profile />} />
          <Route
            path="/admin/order-detail/:id"
            element={<AdminOrderDetail />}
          />
          <Route path="/admin/profile" element={<Profile />} />
          <Route path="/admin/manage-items" element={<ManageItems />} />
          <Route path="/admin/add-category" element={<AddCategory />} />
          <Route
            path="/admin/update-category/:id"
            element={<UpdateCategory />}
          />
          <Route path="/admin/category-item/:id" element={<CategoryItem />} />
          <Route
            path="/admin/add-category-item/:id"
            element={<AddCategoryItem />}
          />
          <Route
            path="/admin/update-category-item/:id"
            element={<UpdateCategoryItem />}
          />
          <Route path="/admin/update-addon/:id" element={<UpdateAddon />} />
          <Route path="/admin/add-addon/:id" element={<AddAddon />} />
          <Route path="/admin/manage-tables" element={<ManageTables />} />
          <Route path="/admin/add-table" element={<AddTable />} />
          <Route path="/admin/update-table/:id" element={<UpdateTable />} />
        </Route>
        {/* client route */}
        <Route path="/" element={<SplashScreen />} />
        <Route path="/splashscreen" element={<SplashScreen />} />
        <Route element={<ClientProtectedRoute />}>
          <Route path="/menu" element={<Menu />} />
          <Route path="/place-order" element={<PlaceOrder />} />
          <Route path="/order-placed" element={<OrderPlaced />} />
          <Route path="/my-orders" element={<MyOrders />} />
          <Route path="/order-detail/:id" element={<OrderDetail />} />
          <Route path="/waiter" element={<Waiter />} />
          <Route path="/search" element={<Search />} />
        </Route>
        <Route path="*" element={<Navigate to="/splashscreen" replace />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
