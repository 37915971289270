import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.localStorage.clear();
      window.location.href = `/admin/login`;
    }
    return Promise.reject(error);
  }
);
