import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import back from "assets/images/arrow-left.svg";
import { showNotification } from "utils/customNotification";
import { useDispatch, useSelector } from "react-redux";
import { updateAddon } from "store/Features/Category/Category Items/categoryItemsServices";
import { usePageTitle } from "hooks/usePageTitle";
import { MiniSpinnerComponent, SpinnerComponent } from "components/Spinner";
import { useLocale } from "localization/context";

const UpdateAddon = () => {
  const {
    state: { update_addon },
  } = useLocale();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { updateAddonList } = useSelector((state) => state.items);
  const nameRef = useRef();
  const priceRef = useRef();
  const [updating, setUpdating] = useState(false);
  const [isDataLoading, setIsDataLoding] = useState(true);
  usePageTitle(update_addon.updateAddonTitle);

  useEffect(() => {
    const currAddon = updateAddonList?.find(
      (addOn) => addOn.id === parseInt(id)
    );
    nameRef.current.value = currAddon.title;
    priceRef.current.value = currAddon.price;
    setIsDataLoding(false);
  }, [id]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (updating) return;
    const name = nameRef.current.value;
    const price = priceRef.current.value;

    if (!name || !price) {
      showNotification("error", update_addon.enterFieldsPrompt);
      return;
    }
    setUpdating(true);
    dispatch(updateAddon({ addon_id: id, title: name, price }))
      .unwrap()
      .then((promiseResult) => {
        if (promiseResult.status) {
          // navigate(`/admin/update-category-item/${editAddon.category_id}`);
          navigate(-1);
        } else {
          showNotification("error", update_addon.failedUpdatePrompt);
          navigate(-1);
        }
      })
      .finally(() => setUpdating(false));
  };

  return (
    <div className="add-category add-category-item h-100">
      <div className="page-title position-relative">
        <div className="cus-container d-flex justify-content-center">
          {!updating && (
            <Link>
              <img src={back} alt="back" onClick={() => navigate(-1)} />
            </Link>
          )}
          <h4 className="text-capitalize">{update_addon.updateAddon}</h4>
        </div>
      </div>
      {isDataLoading && <SpinnerComponent />}
      <form onSubmit={handleSubmit} className="h-100">
        <div className="cus-container admin-scroll">
          <div className="floating-input">
            <label>{update_addon.nameLabel}</label>
            <input
              type="text"
              placeholder={update_addon.enterNamePlaceholder}
              ref={nameRef}
              className="floating-control"
            />
          </div>
          <div className="floating-input">
            <label>{update_addon.priceLabel}</label>
            <input
              type="text"
              placeholder={update_addon.enterPricePlaceholder}
              ref={priceRef}
              className="floating-control"
            />
          </div>
        </div>
        <div className="bottom-btn">
          <div className="cus-container">
            <button
              type="submit"
              className="border-0 common-btn"
              disabled={updating}
            >
              {updating ? (
                <>
                  {update_addon.updatingButton} <MiniSpinnerComponent />
                </>
              ) : (
                update_addon.updateButton
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateAddon;
