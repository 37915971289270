import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const backendURL = process.env.REACT_APP_API_BASE_URL;


export const getCustomerToken = createAsyncThunk(
  "customerCategory/getCustomerToken",
  async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.get(`${backendURL}/config-setting`, config);
      return data;
    } catch (error) {
      const errorMessage = error.response?.data?.message ?? error.message;
      return Promise.reject(errorMessage);
    }
  }
);

export const getCustomerCategory = createAsyncThunk(
  "customerCategory/getCustomerCategory",
  async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_customer_token"
          )} `,
        },
      };
      const { data } = await axios.get(`${backendURL}/category-list-customer`, config);
      return data;
    } catch (error) {
      const errorMessage = error.response?.data?.message ?? error.message;
      return Promise.reject(errorMessage);
    }
  }
);

export const getCustomerItemByCategory = createAsyncThunk(
  "customerItemByCategory/getCustomerItemByCategory",
  async ({ category_id }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_customer_token"
          )} `,
        },
      };
      const { data } = await axios.post(`${backendURL}/item-by-category-customer`, { category_id }, config);
      return data;
    } catch (error) {
      const errorMessage = error.response?.data?.message ?? error.message;
      return Promise.reject(errorMessage);
    }
  }
);

export const getCustomerItemAddons = createAsyncThunk(
  "customerItemAddons/getCustomerItemAddons",
  async ({ item_id }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_customer_token"
          )} `,
        },
      };
      const { data } = await axios.post(`${backendURL}/addon-byitem-customer`, { item_id }, config);
      return data;
    } catch (error) {
      const errorMessage = error.response?.data?.message ?? error.message;
      return Promise.reject(errorMessage);
    }
  }
);

export const customerPlaceOrder = createAsyncThunk(
  "customerItemAddons/getCustomerItemAddons",
  async ({ table_id, order_total, items_array, taxes, total_amount }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_customer_token"
          )} `,
        },
      };
      const { data } = await axios.post(`${backendURL}/add-order`, { table_id, order_total, items_array, taxes, total_amount }, config);
      return data;
    } catch (error) {
      const errorMessage = error.response?.data?.message ?? error.message;
      return Promise.reject(errorMessage);
    }
  }
);

export const customerPlacedOrderList = createAsyncThunk(
  "customerOrders/customerPlacedOrderList",
  async ({ ordercode_array, status }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_customer_token"
          )} `,
        },
      };
      const { data } = await axios.post(`${backendURL}/order-list-customer`, { ordercode_array, status }, config);
      return data;
    } catch (error) {
      const errorMessage = error.response?.data?.message ?? error.message;
      return Promise.reject(errorMessage);
    }
  }
);

export const customerGetSingleOrder = createAsyncThunk(
  "customerOrders/customer-get-singleOrder",
  async ({ order_code }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "_apric_customer_token"
          )} `,
        },
      };
      const { data } = await axios.post(`${backendURL}/single-order-customer`, { order_code }, config);
      return data;
    } catch (error) {
      const errorMessage = error.response?.data?.message ?? error.message;
      return Promise.reject(errorMessage);
    }
  }
);
