import React from "react";
import searchIcon from "assets/images/search-icon.svg";
import { useLocale } from "localization/context";

const Search = ({ state, setState }) => {
  const {
    state: { search },
  } = useLocale();
  return (
    <div className="search-control position-relative">
      <button type="button" className="p-0 border-0 bg-transparent">
        <img src={searchIcon} alt="search-icon" />
      </button>
      <input
        type="text"
        placeholder={search.searchPlaceholder}
        value={state}
        onChange={(e) => setState(e.target.value)}
      />
    </div>
  );
};

export default Search;
