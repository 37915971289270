import React, { useRef, useState } from "react";
import back from "assets/images/arrow-left.svg";
import lock from "assets/images/lock-icon.svg";
import logoutIcon from "assets/images/logout-icon.svg";
import userImg from "assets/images/profile-user-img.jpg";
import browseIcon from "assets/images/browse-icon.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  adminChangeProfileImage,
  adminLogout,
} from "store/Features/Auth/Admin/authService";
import { showNotification } from "utils/customNotification";
import validateFileType from "utils/validateFileType";
import { updateProfileImage } from "store/Features/Auth/Admin/authSlice";
import { usePageTitle } from "hooks/usePageTitle";
import { MiniSpinnerComponent } from "components/Spinner";
import { useLocale } from "localization/context";

const Profile = () => {
  const {
    state: { profile },
  } = useLocale();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { adminData, isUpdatedImage, updated_image_url } = useSelector(
    (state) => state.auth
  );
  const [updateImage, setUpdateImage] = useState(
    () => adminData?.profile_pic || null
  );
  const imageRef = useRef();
  const [isPending, setIsPending] = useState(false);
  const [isPendingLogout, setIsPendingLogout] = useState(false);
  usePageTitle(profile.profileTitle);

  const handleUpdateImageInputChange = (name, isFileControl) => {
    if (isFileControl) {
      if (name === "profile-image") {
        const file = imageRef.current.files[0];
        const reader = new FileReader();
        if (!validateFileType(file.type)) {
          showNotification("error", profile.onlySelectImageFilePrompt);
          return;
        }
        setIsPending(true);
        dispatch(adminChangeProfileImage({ profile_pic: file }))
          .unwrap()
          .then((promiseResult) => {
            if (promiseResult) {
              setIsPending(false);
            } else {
              setIsPending(false);
              showNotification("error", profile.errorChangingImagePrompt);
            }
          });
        reader.onload = () => {
          setUpdateImage(reader.result);
          dispatch(updateProfileImage(reader.result));
        };
        reader.readAsDataURL(file);
      }
      return;
    }
  };

  const handleLogout = () => {
    if (isPendingLogout) {
      return;
    }
    setIsPendingLogout(true);
    dispatch(adminLogout({}))
      .unwrap()
      .then((promiseResult) => {
        if (promiseResult.status) {
          navigate("/admin/login");
          showNotification("success", profile.logoutPrompt);
          localStorage.removeItem("_apric_admin_token");
          localStorage.removeItem("isLoggedIn");
        } else {
          showNotification("error", profile.logoutErrorPrompt);
        }
      })
      .finally(() => setIsPendingLogout(false));
  };
  const profileImage =
    (isUpdatedImage ? updated_image_url : updateImage) || userImg;
  return (
    <div className="profile-page">
      <div className="page-title position-relative">
        <div className="cus-container d-flex justify-content-center">
          <Link to="/admin/home">
            <img src={back} alt="back" />
          </Link>
          <h4 className="text-capitalize">{profile.profileText}</h4>
        </div>
      </div>
      <div className="cus-container">
        <div className="profile-cnt d-flex flex-column align-items-center">
          <div
            className="profile-user-img position-relative"
            style={{
              backgroundImage: `url(${profileImage})`,
            }}
          >
            <div className="update-profile">
              {isPending ? (
                <MiniSpinnerComponent />
              ) : (
                <img src={browseIcon} alt="browse" />
              )}
              <input
                type="file"
                accept="image/jpeg,image/png,image/bmp,image/tiff"
                ref={imageRef}
                onChange={(e) =>
                  handleUpdateImageInputChange("profile-image", true)
                }
              />
            </div>
          </div>
          <h3>
            {adminData?.first_name} {adminData?.last_name}
          </h3>
          <p>{adminData?.email}</p>
        </div>
        <ul>
          <li className="profile-links">
            <Link
              to="/admin/change-password"
              className="d-flex align-items-center"
            >
              <img src={lock} alt="lock" />
              <span>{profile.changePasswordText}</span>
            </Link>
          </li>
          <li className="profile-links" onClick={handleLogout}>
            <Link className="d-flex align-items-center">
              <img src={logoutIcon} alt="lock" />
              <span>{profile.logoutText}</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Profile;
