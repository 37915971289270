import { useDispatch } from "react-redux";
import { removeAddon } from "store/Features/Category/customer/customerCategorySlice";

const currency = process.env.REACT_APP_CURRENCY;
const CartItemsList = ({ cartItem, onCartItemChange }) => {
  const dispatch = useDispatch();
  const handleIncreaseQty = () => {
    const newQuantity = cartItem.qty + 1;
    onCartItemChange(cartItem.id, newQuantity, cartItem.itemPricewithAddon);
  };

  const handleDecreaseQty = () => {
    if (cartItem.qty === 1) {
      dispatch(removeAddon(cartItem.id));
    }
    if (cartItem.qty > 0) {
      const newQuantity = cartItem.qty - 1;
      onCartItemChange(cartItem.id, newQuantity, cartItem.itemPricewithAddon);
    }
  };
  return (
    <li className="d-flex">
      <div>
        <h5 className="text-capitalize">{cartItem.food_title}</h5>
        {/* <p>{cartItem.addons ? cartItem.addons.addon_title : ""}</p> */}
        <p>
          {cartItem.addons?.map((addon, index) => (
            <span>
              {addon.addon_title}
              {index !== cartItem.addons.length - 1 && ", "}
            </span>
          ))}
        </p>
        <p className="order-amount">
          {currency}
          {cartItem?.itemPricewithAddon?.toFixed(2)}
        </p>
      </div>
      <div>
        <p className="order-amount">
          {currency}
          {cartItem?.itemPricewithAddon?.toFixed(2)}
        </p>
        <div className="add-remove-qty">
          <div className="d-flex justify-content-center align-items-center">
            <button
              variant="primary"
              onClick={handleDecreaseQty}
              className="p-0 border-0 d-flex align-items-center justify-content-center"
            >
              -
            </button>
            <p>{cartItem.qty}</p>
            <button
              variant="primary"
              onClick={handleIncreaseQty}
              className="p-0 border-0 d-flex align-items-center justify-content-center"
            >
              +
            </button>
          </div>
        </div>
      </div>
    </li>
  );
};

export default CartItemsList;
