import { createSlice } from "@reduxjs/toolkit";
import { getCategory } from "./categoryServices";

const initialState = {
  loading: false,
  error: null,
  message: "",
  isCategoryFetched: false,
  categoryList: [],
  selectedCate: null,
  selectedProd: null,
  products: [],
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    selectedCategory: (state, { payload }) => {
      state.selectedCate = payload;
    },
    selectedProudct: (state, { payload }) => {
      state.selectedProd = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.categoryList = action.payload?.data;
        state.isCategoryFetched = action.payload?.data ? true : false;
      })
      .addCase(getCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message;
      })
  },
});
export const { selectedCategory, selectedProudct } = categorySlice.actions;
export default categorySlice.reducer;
