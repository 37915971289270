import AppRoutes from "./AppRoutes";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <div className="App">
      <ToastContainer />
      <AppRoutes />
    </div>
  );
};
export default App;
