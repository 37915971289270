import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToggleSwitch from "components/ToggleSwitch";
import Dropdown from "react-bootstrap/Dropdown";
import Footer from "components/Footer";

import addItem from "assets/images/add-item-icon.svg";
import equalSign from "assets/images/equal-sign.svg";
import threeDots from "assets/images/three-dots.svg";
import { useDispatch, useSelector } from "react-redux";
import { setActiveState } from "store/Features/Footer/footerSlice";
import {
  changeCategoryStatus,
  deleteCategory,
  getCategory,
} from "store/Features/Category/categoryServices";
import { showNotification } from "utils/customNotification";
import { usePageTitle } from "hooks/usePageTitle";
import { SpinnerComponent } from "components/Spinner";
import { useLocale } from "localization/context";

const ManageItems = () => {
  const {
    state: { manage_items },
  } = useLocale();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { categoryList, loading } = useSelector((state) => state.category);
  const [deleteLoading, setDeleteLoading] = useState(false);
  usePageTitle(manage_items.manageCateoryTitle);

  useEffect(() => {
    dispatch(setActiveState("menu"));
    dispatch(getCategory());
  }, [dispatch]);

  const handleDeleteCategory = (id) => {
    if (deleteLoading) return;
    setDeleteLoading(true);
    dispatch(deleteCategory({ category_id: id }))
      .unwrap()
      .then((promiseResult) => {
        if (promiseResult.status) {
          showNotification("success", manage_items.categoryDeletePrompt);
          dispatch(getCategory());
        } else {
          showNotification("error", manage_items.failedDeletePrompt);
        }
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  };

  const handleToggleChange = (category_id) => {
    dispatch(changeCategoryStatus({ category_id }))
      .unwrap()
      .then((promiseResult) => {
        if (!promiseResult) {
          showNotification("error", manage_items.failedChangeStatusPrompt);
        }
      });
  };

  return (
    <div className="manage-items h-100">
      <div className="page-title position-relative">
        <div className="cus-container d-flex justify-content-center">
          <h4 className="text-capitalize">{manage_items.categoriesText}</h4>
          {!loading && (
            <Link to="/admin/add-category">
              <img src={addItem} alt="add-item" className="add-item-btn" />
            </Link>
          )}
        </div>
      </div>
      <div className="cus-container manage-category">
        {loading && <SpinnerComponent />}
        <ul className="manage-item-list order-detail h-100">
          {categoryList &&
            !loading &&
            categoryList.map((category) => (
              <li key={category.id}>
                <div className="table-box d-flex">
                  <img src={equalSign} alt="equal-sign" />
                  <div
                    className="mng-prd-img"
                    onClick={() =>
                      navigate(`/admin/category-item/${category.id}`)
                    }
                  >
                    <img src={category.image} alt="manage-product" />
                  </div>
                  <div className="d-flex flex-column justify-content-between flex-grow-1">
                    <h5>{category.name}</h5>
                    <div className="d-flex justify-content-between align-items-center">
                      <ToggleSwitch
                        isActive={category.status}
                        id={category.id}
                        handleToggleChange={handleToggleChange}
                      />
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <img
                            src={threeDots}
                            alt="three-dots"
                            loading="lazy"
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              navigate(`/admin/update-category/${category.id}`)
                            }
                          >
                            {manage_items.editDropdown}
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handleDeleteCategory(category.id)}
                          >
                            {manage_items.deleteDropdown}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
      <Footer isAdmin={true} />
    </div>
  );
};

export default ManageItems;
