import { createSlice } from "@reduxjs/toolkit";
import { getTables } from "./manageTableServices";

const initialState = {
  loading: false,
  error: null,
  message: "",
  isTableFetched: false,
  tables: [],
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    selectedCategory: (state, { payload }) => {
      state.selectedCate = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTables.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTables.fulfilled, (state, action) => {
        state.loading = false;
        state.tables = action.payload?.data;
        state.isTableFetched = action.payload?.data ? true : false;
      })
      .addCase(getTables.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export const { selectedCategory } = categorySlice.actions;
export default categorySlice.reducer;
