import React, { useEffect, useState } from "react";
import back from "assets/images/arrow-left.svg";
import { Link, useNavigate } from "react-router-dom";
import { usePageTitle } from "hooks/usePageTitle";
import { useDispatch, useSelector } from "react-redux";
import CartItemsList from "./CartItemsList";
import {
  addItemsToCart,
  clearCartItems,
  clearSelectedAddons,
} from "store/Features/Category/customer/customerCategorySlice";
import { customerPlaceOrder } from "store/Features/Category/customer/customerCategoryService";
import { showNotification } from "utils/customNotification";
import { MiniSpinnerComponent } from "components/Spinner";
import { useLocale } from "localization/context";
const currency = process.env.REACT_APP_CURRENCY;

function PlaceOrder() {
  const {
    state: { place_order },
  } = useLocale();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cartItems } = useSelector((state) => state.customerCategory);
  const [placeCartItems, setPlaceCartItems] = useState(() => cartItems || {});
  const [isPlacingOrder, setIsPlacingOrder] = useState(false);

  usePageTitle(place_order.placeOrderTitle);

  const handleCartItemChange = (
    cartItemId,
    newQuantity,
    itemPricewithAddon
  ) => {
    setPlaceCartItems((prevCartItems) => {
      const updatedItems = prevCartItems.items
        .map((cartItem) => {
          if (cartItem.id === cartItemId) {
            if (newQuantity === 0) {
              return null;
            }
            return {
              ...cartItem,
              price: itemPricewithAddon * newQuantity,
              qty: newQuantity,
            };
          }
          return cartItem;
        })
        .filter(Boolean);

      const totalQuantity = updatedItems.reduce(
        (acc, curr) => acc + curr.qty,
        0
      );
      const totalPrice = updatedItems.reduce(
        (acc, curr) => acc + curr.price,
        0
      );
      return {
        ...prevCartItems,
        totalQuantity,
        totalPrice,
        items: updatedItems,
      };
    });
  };

  useEffect(() => {
    dispatch(addItemsToCart(placeCartItems));
  }, [dispatch, placeCartItems]);

  const itemTotal = placeCartItems.totalPrice;
  const taxAndChanges = itemTotal * 0.1;
  const totalAmountWithTax = itemTotal + taxAndChanges;

  const handlePlaceOrder = () => {
    const placeOrderInfo = {
      table_id: JSON.parse(localStorage.getItem("table_id")),
      order_total: parseFloat(itemTotal.toFixed(2)),
      items_array: JSON.stringify(placeCartItems.items),
      taxes: parseFloat(taxAndChanges.toFixed(2)),
      total_amount: parseFloat(itemTotal.toFixed(2)),
    };
    if (placeOrderInfo.total_amount === 0 && placeOrderInfo.order_total === 0) {
      showNotification("error", place_order.selectItemPrompt);
      return;
    }
    setIsPlacingOrder(true);
    const existingOrderCodes =
      JSON.parse(localStorage.getItem("order_code")) || [];
    dispatch(clearSelectedAddons());
    dispatch(customerPlaceOrder(placeOrderInfo))
      .unwrap()
      .then((promiseResult) => {
        if (promiseResult.status) {
          showNotification("success", place_order.orderPlacedSuccessPrompt);
          const newOrder = promiseResult.data;
          const updateOrderCodes = [...existingOrderCodes, newOrder];
          localStorage.setItem("order_code", JSON.stringify(updateOrderCodes));
          dispatch(clearCartItems());
          navigate("/order-placed");
        } else {
          showNotification("error", place_order.failedPlacingOrder);
        }
      })
      .finally(() => setIsPlacingOrder(false));
  };

  return (
    <div className="place-order h-100">
      <div className="page-title position-relative">
        <div className="cus-container d-flex justify-content-center">
          <Link to="/menu">
            <img src={back} alt="back" />
          </Link>
          <h4 className="text-capitalize">{place_order.placeorderText}</h4>
        </div>
      </div>
      <div className="order-dtl">
        <div className="cus-container h-100">
          <div className="d-flex justify-content-between">
            <p className="order-title">{place_order.orderItemsText}</p>
            <p className="order-title">{place_order.amountText}</p>
          </div>
          <ul className="order-item-list h-100">
            {placeCartItems?.items.map((cartItem) => {
              return (
                <CartItemsList
                  key={cartItem.id}
                  cartItem={cartItem}
                  onCartItemChange={handleCartItemChange}
                />
              );
            })}
          </ul>
        </div>
      </div>
      <div className="bill-detail">
        <div className="cus-container">
          {/* <h5 className="text-capitalize">Bill Details</h5> */}
          <ul className="bill-items-list">
            {/* <li className="d-flex justify-content-between">
              <p>Item Total</p>
              <p>
                {currency}
                {itemTotal.toFixed(2)}
              </p>
            </li>
            <li className="d-flex justify-content-between">
              <p>Taxes and Charges (10%)</p>
              <p>
                {currency}
                {taxAndChanges.toFixed(2)}
              </p>
            </li> */}
            <li className="d-flex justify-content-between">
              <p>{place_order.grandTotalText}</p>
              <p>
                {currency}
                {itemTotal.toFixed(2)}
              </p>
            </li>
          </ul>
          <button
            type="button"
            className="common-btn border-0"
            onClick={handlePlaceOrder}
            disabled={isPlacingOrder}
          >
            {isPlacingOrder ? (
              <>
                {place_order.placingOrderText} <MiniSpinnerComponent />
              </>
            ) : (
              place_order.placeOrderText
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default PlaceOrder;
