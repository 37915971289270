import Spinner from "react-bootstrap/Spinner";

export function SpinnerComponent() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner animation="border" style={{ color: "#FB6D3B" }} />
    </div>
  );
}

export function MiniSpinnerComponent() {
  return (
    <Spinner
      as="span"
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
    />
  );
}
// background: linear-gradient(163deg, #FF7708 0%, #FF5133 100%);
