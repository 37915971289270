import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import back from "assets/images/arrow-left.svg";
import { useDispatch } from "react-redux";
import { showNotification } from "utils/customNotification";
import { addTable } from "store/Features/ManageTable/manageTableServices";
import { usePageTitle } from "hooks/usePageTitle";
import { MiniSpinnerComponent } from "components/Spinner";
import { useLocale } from "localization/context";

const AddTable = () => {
  const {
    state: { add_table },
  } = useLocale();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tableNameRef = useRef();
  const [isPending, setIsPending] = useState(false);
  usePageTitle(add_table.addTableTitle);

  const handleTableSubmit = (event) => {
    if (isPending) {
      return;
    }
    event.preventDefault();
    const name = tableNameRef.current.value;

    if (!name) {
      showNotification("error", add_table.enterTablePrompt);
      return;
    }
    setIsPending(true);
    dispatch(addTable({ name }))
      .unwrap()
      .then((promiseResult) => {
        if (promiseResult.status) {
          showNotification("success", add_table.tableAddSuccessPrompt);
          navigate("/admin/manage-tables");
        } else {
          showNotification("error", add_table.failTableAddPrompt);
          navigate("/admin/manage-tables");
        }
      })
      .finally(() => setIsPending(false));
  };
  return (
    <div className="add-category add-category-item h-100">
      <div className="page-title position-relative">
        <div className="cus-container d-flex justify-content-center">
          <Link to="/admin/manage-tables">
            <img src={back} alt="back" />
          </Link>
          <h4 className="text-capitalize">{add_table.addNewTableText}</h4>
        </div>
      </div>
      <form onSubmit={handleTableSubmit} className="h-100">
        <div className="cus-container admin-scroll">
          <div className="floating-input">
            <label>{add_table.nameLabel}</label>
            <input
              type="text"
              placeholder={add_table.enterNamePlaceholder}
              ref={tableNameRef}
              className="floating-control"
            />
          </div>
        </div>
        <div className="bottom-btn">
          <div className="cus-container">
            <button
              type="submit"
              className="border-0 common-btn"
              disabled={isPending}
            >
              {isPending ? (
                <>
                  {add_table.addingText} <MiniSpinnerComponent />
                </>
              ) : (
                add_table.saveText
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddTable;
