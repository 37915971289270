import React, { useRef, useState } from "react";
import back from "assets/images/arrow-left.svg";
import { Link, useNavigate } from "react-router-dom";
import { showNotification } from "utils/customNotification";
import { useDispatch } from "react-redux";
import { adminChangePassword } from "store/Features/Auth/Admin/authService";
import { usePageTitle } from "hooks/usePageTitle";
import { MiniSpinnerComponent } from "components/Spinner";
import { useLocale } from "localization/context";

const ChangePassword = () => {
  const {
    state: { change_password },
  } = useLocale();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const oldPasswordRef = useRef();
  const newPasswordRef = useRef();
  const confirmPasswordRef = useRef();
  const [isPending, setIsPending] = useState(false);
  usePageTitle(change_password.changePasswordTitle);

  const handleSubmit = (event) => {
    event.preventDefault();
    const old_password = oldPasswordRef.current.value;
    const new_password = newPasswordRef.current.value;
    const confirm_password = confirmPasswordRef.current.value;

    if (!old_password || !new_password || !confirm_password) {
      showNotification("error", change_password.enterAllFieldsPrompt);
      return;
    }
    if (new_password !== confirm_password) {
      showNotification("error", change_password.passwordDoNotMatchPrompt);
      return;
    }

    setIsPending(true);
    dispatch(
      adminChangePassword({ old_password, new_password, confirm_password })
    )
      .unwrap()
      .then((promiseResult) => {
        if (promiseResult.status) {
          showNotification(
            "success",
            change_password.passwordChangeSuccessPrompt
          );
          navigate("/admin/profile");
        } else {
          showNotification("error", change_password.failedChangePassPrompt);
          navigate("/admin/profile");
        }
      })
      .finally(() => {
        setIsPending(false);
      });
  };
  return (
    <div className="change-password h-100">
      <div className="page-title position-relative">
        <div className="cus-container d-flex justify-content-center">
          {!isPending && (
            <Link to="/admin/profile">
              <img src={back} alt="back" />
            </Link>
          )}
          <h4 className="text-capitalize">{change_password.change_password}</h4>
        </div>
      </div>
      <div className="cus-container">
        <form onSubmit={handleSubmit}>
          <div className="from-group">
            <span>{change_password.oldPasswordLabel}</span>
            <input
              type="password"
              placeholder={change_password.enterOldPasswordPlaceholder}
              ref={oldPasswordRef}
            />
          </div>
          <div className="from-group">
            <span>{change_password.newPasswordLabel}</span>
            <input
              type="password"
              placeholder={change_password.enterNewPasswordPlaceholder}
              ref={newPasswordRef}
            />
          </div>
          <div className="from-group">
            <span>{change_password.confirmPasswordLabel}</span>
            <input
              type="password"
              placeholder={change_password.enterConfirmPasswordPlaceholder}
              ref={confirmPasswordRef}
            />
          </div>
          <div className="bottom-btn">
            <div className="cus-container">
              <button
                type="submit"
                className="border-0 common-btn"
                disabled={isPending}
              >
                {isPending ? (
                  <>
                    {change_password.updatingButton} <MiniSpinnerComponent />
                  </>
                ) : (
                  change_password.saveAndUpdate
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default ChangePassword;
