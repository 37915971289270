import React from "react";
import { Link } from "react-router-dom";

import menuIcon from "assets/images/menu-icon.png";
import orderIcon from "assets/images/order-icon.png";
import waiterIcon from "assets/images/waiter-icon.png";
import { useSelector } from "react-redux";
import { useLocale } from "localization/context";

const Footer = ({ isAdmin }) => {
  const { activeState } = useSelector((state) => state.activeState);
  const {
    state: { footer },
  } = useLocale();
  return (
    <>
      {!isAdmin ? (
        <ul className="menu-sec d-flex justify-content-between">
          <li>
            <Link to="/menu" className={activeState === "menu" ? "active" : ""}>
              <img src={menuIcon} alt="menu-icon" />
              <p>{footer.menu}</p>
            </Link>
          </li>
          <li>
            <Link
              to="/my-orders"
              className={activeState === "my-orders" ? "active" : ""}
            >
              <img src={orderIcon} alt="menu-icon" />
              <p>{footer.orders}</p>
            </Link>
          </li>
          <li>
            <Link
              to="/waiter"
              className={activeState === "waiter" ? "active" : ""}
            >
              <img src={waiterIcon} alt="menu-icon" />
              <p>{footer.waiter}</p>
            </Link>
          </li>
        </ul>
      ) : (
        <ul className="menu-sec d-flex justify-content-between">
          <li>
            <Link
              to="/admin/home"
              className={activeState === "orders" ? "active" : ""}
            >
              <img src={menuIcon} alt="menu-icon" />
              <p>{footer.orders}</p>
            </Link>
          </li>
          <li>
            <Link
              to="/admin/manage-items"
              className={activeState === "menu" ? "active" : ""}
            >
              <img src={orderIcon} alt="menu-icon" />
              <p>{footer.menu}</p>
            </Link>
          </li>
          <li>
            <Link
              to="/admin/manage-tables"
              className={activeState === "managetables" ? "active" : ""}
            >
              <img src={waiterIcon} alt="menu-icon" />
              <p>{footer.manageTables}</p>
            </Link>
          </li>
        </ul>
      )}
    </>
  );
};

export default Footer;
