import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "components/Footer";

import waiterImg from "assets/images/waiter.svg";
import { setActiveState } from "store/Features/Footer/footerSlice";
import { useDispatch } from "react-redux";
import { usePageTitle } from "hooks/usePageTitle";
import { useLocale } from "localization/context";

const Waiter = () => {
  const {
    state: { waiter },
  } = useLocale();
  const dispatch = useDispatch();

  usePageTitle(waiter.waiterTitle);
  useEffect(() => {
    dispatch(setActiveState("waiter"));
  }, [dispatch]);

  return (
    <div className="waiter">
      <div className="cus-container">
        <div className="text-center">
          <img src={waiterImg} alt="waiter" />
          <h2>
            {waiter.wouldYouLikeToText}
            <br></br> {waiter.speakToTheWaiterText}
          </h2>
          <Link to="/waiter" className="common-btn">
            {waiter.callWaiterText}
          </Link>
          <div className="select-payment">
            <h4>{waiter.selectPaymentMethodText}</h4>
            <div className="d-flex justify-content-center payment-list">
              <label className="cus-radio position-relative">
                <input type="radio" name="radio" checked />
                <span class="radio-bg"></span>
                <span class="radio-chk"></span>
                <p>{waiter.cardText}</p>
              </label>
              <label className="cus-radio position-relative">
                <input type="radio" name="radio" />
                <span class="radio-bg"></span>
                <span class="radio-chk"></span>
                <p>{waiter.cashText}</p>
              </label>
            </div>
            <button className="border-0 common-btn">
              {waiter.submitButtonText}
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Waiter;
